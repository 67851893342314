import * as React from 'react';
import styled from 'styled-components';
import {
  Table,
  Tabs,
  Tab,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';

import {
  AccountingRunContractConsumptionContract,
  ValidateContractConsumptionsStepResult,
} from '../../../../graphql-types';
import { translate } from '../helpers/translationHelper';

export const columns: ColumnDefinition<AccountingRunContractConsumptionContract>[] =
  [
    {
      Header: 'Mieter',
      accessor: 'name',
      type: 'string',
    },
    {
      Header: 'KundenNr.',
      accessor: 'customerLabel',
      type: 'string',
    },
    {
      Header: 'VertragsNr.',
      accessor: 'contractLabel',
      type: 'string',
    },
  ];

const columnsWithError: ColumnDefinition<AccountingRunContractConsumptionContract>[] =
  [
    ...columns,
    {
      Header: 'Fehler',
      accessor: 'errors',
      Cell: ({ value }: { value: string[] }) =>
        value.map((e, index) => (
          <div key={`${e}-${index}`}>
            <>{translate(`${e}.desc`, e)}</>
          </div>
        )),
    },
  ];

const Spacer = styled.div`
  margin-top: 10px;
`;

interface ValidateContractConsumptionStepProps {
  result: ValidateContractConsumptionsStepResult;
}

export function ValidateContractConsumptionStep({
  result,
}: ValidateContractConsumptionStepProps) {
  const { contracts } = result;

  const rowLink = React.useCallback(
    ({ row }: any) => {
      const entry = row.original as AccountingRunContractConsumptionContract;
      const contract = contracts?.find((c) => c.id === entry.id);
      const baseURL = `/project/${contract?.projectId}/plant/${contract?.plantId}/tenant`;

      return `${baseURL}/contract/${entry.id}`;
    },
    [contracts],
  );

  if (!contracts) {
    return null;
  }

  const succeeded = contracts.filter((c) => c.errors?.length === 0);
  const failed = contracts.filter((c) => c.errors?.length !== 0);

  return (
    <Tabs>
      {/* @ts-expect-error old Tab */}
      <Tab title={`${succeeded.length} Erfolgreich`}>
        <Spacer>
          <Table
            columns={columns}
            data={succeeded}
            filterKind="Erfolgreiche Verträge"
            isLoading={false}
            rowLink={rowLink}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
      {/* @ts-expect-error old Tab */}
      <Tab
        title={`${failed.length} Fehlerhaft`}
        hasNotification={failed.length !== 0}
      >
        <Spacer>
          <Table
            columns={columnsWithError}
            data={failed}
            filterKind="Fehlerhafte Verträge"
            isLoading={false}
            rowLink={rowLink}
            openInNewTab
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
            withFilter
          />
        </Spacer>
      </Tab>
    </Tabs>
  );
}
