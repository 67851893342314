import 'styled-components';
import { RadioButton, RadioGroup } from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import { get, merge } from 'lodash';
import React from 'react';

import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../components/graphql-form/render';
import { Grid } from '../../../components/layout';
import { BankingDetailsForm } from '../create/BankingDetailsForm';
import { StyledLabel } from '../sharedForms';

export interface FormPartProps {
  fieldNamePrefix?: string;
}

export function PersonForm({
  fieldNamePrefix = 'customer.person',
}: FormPartProps) {
  const { setFieldValue, initialValues, values } = useFormikContext();
  const { isEditing } = useGraphqlForm();
  const initialPersonValues = get(initialValues, fieldNamePrefix);

  const companyFlag = get(values, `${fieldNamePrefix}.companyFlag`, false);
  const initialCo = get(initialPersonValues, 'co', '');

  if (!isEditing) {
    return (
      <>
        <GraphqlFormInputGroup>
          {!initialPersonValues?.companyFlag && (
            <GraphqlFormField
              name={`${fieldNamePrefix}.namePrefix`}
              label={null}
            />
          )}
          <GraphqlFormField name={`${fieldNamePrefix}.name`} label={null} />
        </GraphqlFormInputGroup>
        {companyFlag && initialCo ? (
          <span css="display: flex; gap: 0.5rem; align-items: baseline;">
            c/o <GraphqlFormField name={`${fieldNamePrefix}.co`} label={null} />
          </span>
        ) : null}
      </>
    );
  }

  return (
    <>
      <RadioGroup
        value={companyFlag}
        id={`${fieldNamePrefix}.companyFlag`}
        name={`${fieldNamePrefix}.companyFlag`}
        onChange={(value) => {
          setFieldValue(
            `${fieldNamePrefix}.companyFlag`,
            value === 'true',
            false,
          );
        }}
        direction="row"
      >
        <RadioButton value={false} label="Person" />
        <RadioButton value label="Firma" />
      </RadioGroup>
      {!companyFlag ? (
        <PersonAddressForm fieldNamePrefix={fieldNamePrefix} />
      ) : (
        <>
          <GraphqlFormField
            name={`${fieldNamePrefix}.name`}
            label={null}
            placeholder="Firmenname"
          />
          <GraphqlFormField
            name={`${fieldNamePrefix}.co`}
            label={null}
            prependix="c/o"
            placeholder={null}
          />
        </>
      )}
    </>
  );
}

function PersonAddressForm({ fieldNamePrefix }: FormPartProps) {
  return (
    <Grid>
      <GraphqlFormField
        id={`${fieldNamePrefix}.namePrefix`}
        data-testid={`${fieldNamePrefix}.namePrefix`}
        name={`${fieldNamePrefix}.namePrefix`}
        label={null}
        renderEmptyString={false}
      />
      <GraphqlFormField name={`${fieldNamePrefix}.name`} label={null} />
    </Grid>
  );
}

export function PaymentEditForm({ fieldNamePrefix }: FormPartProps) {
  const { isEditing, isLoading } = useGraphqlForm();
  const { values, setValues, initialValues } = useFormikContext();
  const hasSepa = get(values, 'customer.hasSepa') === true;
  const initialHasSepa = get(initialValues, 'customer.hasSepa') === true;

  const setSepa = (hasSepaValue: boolean) => {
    if (hasSepaValue === false) {
      setValues(
        merge({}, values, {
          customer: {
            hasSepa: false,
          },
        }),
      );
    } else if (!initialHasSepa) {
      setValues(
        merge({}, values, {
          customer: {
            bankAccount: {
              name: '',
              iban: '',
              bic: '',
              mandateReference: '',
              signedAt: '',
            },
            hasSepa: true,
          },
        }),
      );
    } else {
      setValues(
        merge({}, values, {
          customer: {
            hasSepa: true,
          },
        }),
      );
    }
  };

  if (isLoading) {
    return null;
  }

  if (!isEditing) {
    return (
      <>
        {!hasSepa ? 'Selbstzahler' : 'SEPA-Bankeinzug'}
        <BankingDetailsForm
          isSelfPayer={!hasSepa}
          fieldNamePrefix={fieldNamePrefix}
        />
      </>
    );
  }

  return (
    <>
      <input
        type="radio"
        data-testid="self-payer"
        id="self-payer"
        checked={!hasSepa}
        onClick={() => setSepa(false)}
        readOnly
      />
      <StyledLabel htmlFor="self-payer">Selbstzahler</StyledLabel> <br />
      <input
        type="radio"
        data-testid="sepa-payment"
        id="sepa-payment"
        checked={hasSepa}
        onClick={() => setSepa(true)}
        readOnly
      />
      <StyledLabel htmlFor="sepa-payment">SEPA-Bankeinzug</StyledLabel>
      <br />
      <BankingDetailsForm
        isSelfPayer={!hasSepa}
        fieldNamePrefix={fieldNamePrefix}
      />
    </>
  );
}
