import * as React from 'react';
import {
  Flex,
  FlexRow,
  formatDate,
  formatString,
} from '@ampeersenergy/ampeers-ui-components';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';

import { Entry } from '../../../../components';
import GraphQlForm from '../../../../components/graphql-form';
import { useGraphqlForm } from '../../../../components/graphql-form/hooks/useGraphqlForm';
import { GraphqlFormField } from '../../../../components/graphql-form/render';
import {
  ReadAccountingDetailsReportQuery,
  ReadContractDocument,
  ReadContractQuery,
  useReadContractAccountingHistoryQuery,
  useReadContractAccountingQuery,
} from '../../../../graphql-types';
import {
  formatContractStatus,
  formatEmgEndDates,
} from '../../../../helpers/formatStrings';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import { readAccountingDetailsReport } from '../../../../queries/readAccountingDetailsReport';
import { useHasRole } from '../../../../components/useHasRole';

type Contract = ReadContractQuery['readContract'];

interface ContractEditProps {
  contract?: Contract;
  isLoading?: boolean;
}

const validationSchema = (
  initialValues: Contract,
  lastAccountingEnd?: string,
) => ({
  status: yup
    .string()
    .test(
      'valid-status',
      (value: string | undefined, context: yup.TestContext) => {
        if (value === initialValues?.status) return true;
        if (!value || !initialValues?.status) return false;

        if (
          ['LS_INTRANSFER', 'FS_ACTIVE'].includes(initialValues.status) &&
          value === 'LS_ACTIVE'
        ) {
          return true;
        }
        if (
          initialValues.status === 'FS_INTRANSFER' &&
          ['FS_ACTIVE', 'LS_ACTIVE'].includes(value)
        ) {
          return true;
        }

        return context.createError({
          message: `Der Status kann nur von 'In Transfer' zu 'In Belieferung' geändert werden bzw. von 'In Belieferung (Vollversorgung)' zu 'In Belieferung (Mieterstrom)'`,
        });
      },
    )
    .required(),
  startDate: yupUTCDate.required(),
  endDate: yupUTCDate
    .test(
      'no-conflict',
      (value: Date | undefined, context: yup.TestContext) => {
        if (!value) return false;
        const endDate = DateTime.fromJSDate(value);
        const startDate = DateTime.fromISO(initialValues?.startDate);
        const lastAccountingEndDate = lastAccountingEnd
          ? DateTime.fromISO(lastAccountingEnd)
          : null;

        if (endDate < startDate) {
          return context.createError({
            message:
              'Das neue Lieferende darf nicht vor dem Lieferbeginn liegen.',
          });
        }

        if (lastAccountingEndDate && endDate < lastAccountingEndDate) {
          const lastAccountingEndDateString =
            lastAccountingEndDate.toFormat('dd.MM.yyyy');

          return context.createError({
            message: `Das neue Lieferende darf nicht vor dem Ende der letzten Abrechnungsperiode (${lastAccountingEndDateString}) liegen.`,
          });
        }

        return true;
      },
    )
    .required(),
});

export function ContractEdit({ contract, isLoading }: ContractEditProps) {
  // TODO: Needs to be done when we handle accounting history
  const { data: accountingHistory } = useReadContractAccountingHistoryQuery({
    variables: {
      contractId: contract?.id || '',
    },
    skip: !contract?.id,
  });

  const { lastAccountingEnd } =
    accountingHistory?.readContractAccountingHistory ?? {};

  const validation = validationSchema(contract, lastAccountingEnd);
  return (
    <GraphQlForm
      mutation="updateContractStatus"
      readDocument={ReadContractDocument}
      values={contract}
      variables={{
        contractId: contract?.id,
      }}
      refetchQueries={[
        {
          query: ReadContractDocument,
          variables: {
            contractId: contract?.id,
          },
        },
      ]}
      validation={validation}
      isLoading={isLoading}
    >
      <ContractEditForm />
    </GraphQlForm>
  );
}

// If the contractendDate is the same as the endDate of the invoice, then the invoice is of type "Schlussrechnung"
function isFinalAccounting(invoiceDate: string, contractDate: string) {
  const diff = DateTime.fromISO(invoiceDate.substring(0, 10)).diff(
    DateTime.fromISO(contractDate.substring(0, 10)),
  );
  return diff.toObject().milliseconds === 0;
}

function ContractEditForm() {
  const client = useApolloClient();
  const { isLoading, isEditing } = useGraphqlForm();
  const { initialValues: contract, values } = useFormikContext<Contract>();

  const [hasInvoiceWithFinalAccounting, setHasInvoiceWithFinalAccounting] =
    React.useState(false);
  const statusDisabled = ![
    'LS_INTRANSFER',
    'FS_INTRANSFER',
    'FS_ACTIVE',
  ].includes(contract?.status ?? '');

  const { hasRole: isChangeDateEnabled } = useHasRole(
    'feature_contract_change_dates',
  );

  const startDateDisabled = !['LS_INTRANSFER', 'FS_INTRANSFER'].includes(
    contract?.status ?? '',
  );

  const endDateDisabled = ![
    'LS_INCANCELATION',
    'LS_ENDED',
    'FS_INCANCELATION',
    'FS_ENDED',
  ].includes(contract?.status ?? '');

  const { data: readContractAccountingData } = useReadContractAccountingQuery({
    variables: {
      contractId: contract?.id ?? '',
      contractLabel: contract?.label ?? '',
      startAt: DateTime.fromISO(contract?.startDate).startOf('day').toISO(),
      endAt: DateTime.now().endOf('day').toISO(),
    },
    skip: endDateDisabled,
  });

  const { hasRole: hasFullStatusSupply } = useHasRole(
    'feature_status_full_supply',
  );

  React.useEffect(() => {
    // If the contract has a invoice of type final accounting(Schlussrechnung), the user shouldn't change the endDate.
    // We determine if a invoice is of type final accounting if invoice.accountingPeriodEndDate === contract.endDate
    async function calculateFinalAccountingInvoice() {
      const finalAccountingAccountReports =
        readContractAccountingData?.readAccountMoves?.processed.map(
          async ({ opcName }) => {
            if (opcName) {
              const reportsData: ReadAccountingDetailsReportQuery = (
                await client.query({
                  query: readAccountingDetailsReport,
                  fetchPolicy: 'network-only',
                  variables: { opcName },
                })
              ).data;
              return reportsData.readAccountingDetailsReport.some((report) => {
                return isFinalAccounting(
                  report.accountingPeriodEndDate,
                  contract?.endDate,
                );
              });
            }
            return false;
          },
        );
      const isThereReportsWithFinalAccount = await Promise.all(
        finalAccountingAccountReports || [],
      ).then((res) => {
        return res.some(
          (hasAccountReportFinalAccounting) => hasAccountReportFinalAccounting,
        );
      });
      setHasInvoiceWithFinalAccounting(isThereReportsWithFinalAccount);
    }
    if (readContractAccountingData?.readAccountMoves && contract?.endDate) {
      calculateFinalAccountingInvoice();
    }
  }, [readContractAccountingData, contract?.endDate, client]);

  function getAvailableStatusValues(status: string) {
    let availableStatus: string[];
    switch (status) {
      case 'LS_INTRANSFER':
        availableStatus = ['LS_ACTIVE'];
        break;
      case 'FS_INTRANSFER':
        availableStatus = hasFullStatusSupply
          ? ['FS_ACTIVE', 'LS_ACTIVE']
          : ['LS_ACTIVE'];
        break;
      case 'FS_ACTIVE':
        availableStatus = ['LS_ACTIVE'];
        break;
      default:
        availableStatus = [];
    }
    return [status, ...availableStatus];
  }

  return (
    <>
      <FlexRow>
        <Flex>
          <Entry title="Vertragsnummer" isLoading={isLoading}>
            {contract?.label}
          </Entry>
        </Flex>
        <Flex>
          {isEditing && !statusDisabled ? (
            <GraphqlFormField
              name="status"
              disabled={statusDisabled}
              labels={getAvailableStatusValues(contract?.status ?? '').map(
                (status) => {
                  const { title, description } = formatContractStatus(
                    status,
                    hasFullStatusSupply,
                  );

                  return {
                    title,
                    description,
                  };
                },
              )}
              values={getAvailableStatusValues(contract?.status ?? '')}
              initialValue={values?.status}
              type="dropdown"
            />
          ) : (
            <Entry title="Status" isLoading={isLoading}>
              {
                formatContractStatus(contract?.status, hasFullStatusSupply)
                  .title
              }
            </Entry>
          )}
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          <Entry title="Datum Vertragsunterschrift" isLoading={isLoading}>
            {formatDate(contract?.signDate)}
          </Entry>
        </Flex>
        <Flex>
          <GraphqlFormField
            name="startDate"
            disabled={startDateDisabled || !isChangeDateEnabled}
          />
          {!isChangeDateEnabled && !startDateDisabled && isEditing && (
            <small>
              Aktuell ist die Änderung des Lieferbeginns nicht möglich. Bitte
              wenden Sie sich an den Support.
            </small>
          )}
        </Flex>
      </FlexRow>
      <FlexRow>
        <Flex>
          {isEditing ? (
            <GraphqlFormField
              name="endDate"
              disabled={
                endDateDisabled ||
                hasInvoiceWithFinalAccounting ||
                !isChangeDateEnabled
              }
            />
          ) : (
            <Entry title="Lieferende" isLoading={isLoading}>
              {formatEmgEndDates(contract?.endDate, true, false)}
            </Entry>
          )}
          {!isChangeDateEnabled && !endDateDisabled && isEditing && (
            <small>
              Aktuell ist die Änderung des Lieferendes nicht möglich. Bitte
              wenden Sie sich an den Support.
            </small>
          )}
        </Flex>
        <Flex />
      </FlexRow>
      <FlexRow>
        <Flex>
          <GraphqlFormField name="workspace.id" />
        </Flex>
        <Flex>
          <Entry title="Debitorennummer" isLoading={isLoading}>
            {formatString(contract?.debtorLabel)}
          </Entry>
        </Flex>
      </FlexRow>
    </>
  );
}
