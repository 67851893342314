import * as React from 'react';
import styled from 'styled-components';
import {
  Button,
  ButtonGroup,
  ErrorAlert,
  Icons,
  Modal,
} from '@ampeersenergy/ampeers-ui-components';
import * as yup from 'yup';
import ContentLoader from 'react-content-loader';
import { Form, Formik, useFormikContext } from 'formik';

import { DocumentType, useGetDocumentTypesQuery } from '../../graphql-types';
import { FormikSelect, FormikSubmit } from '../input';

import useDocumentUpload from './useDocumentUpload';
import { useFileUploadWrapper } from './UploadDropzone';
import FileUpload from './FileUpload';
import FilePreview from './FilePreview';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
`;

const UploadForm = styled.div`
  margin-top: 2rem;
  max-width: 450px;
`;

const FormAction = styled(ButtonGroup)`
  justify-content: flex-end;
`;

interface DocumentUploadDialogProps {
  onClose?: () => void;
}

type TransformedDocumentGroup = {
  [key: string]: [
    {
      name: string;
      label: string;
    },
  ];
};

function transformDocumentTypes(type?: DocumentType[]) {
  if (!type) return {};
  return type.reduce((acc: TransformedDocumentGroup, curr) => {
    const { group, name, label } = curr;
    const documentGroup = group ?? 'other';
    if (!acc[documentGroup]) {
      acc[documentGroup] = [{ name, label }];
    } else {
      acc[documentGroup].push({ name, label });
    }
    return acc;
  }, {});
}

function UploadDocumentForm({ onClose }: DocumentUploadDialogProps) {
  const { accept, file, maxSize } = useFileUploadWrapper();
  const [selectedFile, setSelectedFile] = React.useState<File | null>(file);

  const { data, loading } = useGetDocumentTypesQuery();
  const { setFieldValue, errors, isSubmitting, touched } = useFormikContext<{
    document?: File | null;
    documentType?: string;
  }>();

  React.useEffect(() => {
    if (selectedFile) {
      setFieldValue('document', selectedFile);
    } else if (touched.document) {
      setFieldValue('document', null);
    }
  }, [setFieldValue, touched, selectedFile]);

  const documentTypesGrouped = React.useMemo(
    () => transformDocumentTypes(data?.getDocumentTypes),
    [data?.getDocumentTypes],
  );

  return (
    <UploadForm>
      {selectedFile ? (
        <FilePreview
          file={selectedFile}
          disabled={isSubmitting}
          onRemove={() => setSelectedFile(null)}
        />
      ) : (
        <FileUpload
          accept={accept}
          maxSize={maxSize}
          disabled={isSubmitting}
          errors={touched ? errors : undefined}
          name="document"
          onSelect={setSelectedFile}
        />
      )}
      {loading ? (
        <ContentLoader
          id="content-loader-modal"
          key="content-loader-select"
          speed={2}
          height={70}
          style={{ width: '100%' }}
        >
          <rect x="0" y="4" rx="1" ry="1" width="40" height="20" />
          <rect x="0" y="28" rx="1" ry="1" width="100%" height="32" />
        </ContentLoader>
      ) : (
        <FormikSelect
          label="Typ"
          id="documentType"
          name="documentType"
          data-testid="documentType"
        >
          <option value="">Bitte wählen...</option>
          {Object.keys(documentTypesGrouped).map((group) => (
            <optgroup key={group} label={group}>
              {documentTypesGrouped[group].map(({ name, label }) => (
                <option key={name} value={name}>
                  {label}
                </option>
              ))}
            </optgroup>
          ))}
        </FormikSelect>
      )}
      <p>
        Wähle den passenden Typ aus, damit wir dein Dokument richtig einordnen
        können. 🪄
      </p>
      <FormAction>
        <Button onClick={onClose} disabled={isSubmitting} secondary>
          Abbrechen
        </Button>
        <FormikSubmit type="submit">Hochladen</FormikSubmit>
      </FormAction>
    </UploadForm>
  );
}

const validationSchema = yup.object().shape({
  document: yup.mixed().required('Es muss eine Datei ausgewählt werden'),
  documentType: yup.string().required('Typ ist erforderlich'),
});

interface DocumentUploadProps extends DocumentUploadDialogProps {
  open: boolean;
  contractId?: string;
  onSuccess?: () => void;
}

export default function DocumentUpload({
  contractId,
  open,
  onClose,
  onSuccess,
}: DocumentUploadProps) {
  const [error, setError] = React.useState<string>('');
  const { onFileSelect } = useFileUploadWrapper();

  const { uploadDocument } = useDocumentUpload(contractId ?? '');
  const [isComplete, setIsComplete] = React.useState(false);

  const closedDialog = () => {
    setIsComplete(false);
    if (onFileSelect) onFileSelect(null);
    if (onClose) onClose();
  };

  if (!contractId) {
    return null;
  }

  return (
    <Modal
      isOpen={open}
      title="Dokument hinzufügen"
      onRequestClose={closedDialog}
      contentLabel="documentupload-modal"
      minWidth={450}
    >
      {error && <ErrorAlert>{error}</ErrorAlert>}
      {isComplete ? (
        <Center data-testid="document-upload-success">
          <div>
            <Icons.Checkmark size={60} color="#A8E015" />
          </div>
          Dokument erfolgreich hinzugefügt
        </Center>
      ) : (
        <Formik
          initialValues={{ document: '', documentType: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setError('');
            try {
              await uploadDocument(values.document as any, values.documentType);
              resetForm();
              setSubmitting(false);
              setIsComplete(true);
              if (onSuccess) onSuccess();
            } catch (err) {
              setSubmitting(false);
              if (err instanceof Error) {
                setError(err.message);
              }
            }
          }}
        >
          {() => (
            <Form>
              <UploadDocumentForm onClose={closedDialog} />
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
}
