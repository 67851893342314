/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import {
  Button,
  ColumnDefinition,
  Headline as HeadlineComponent,
  Icons,
  Main,
  Section,
  Table,
  defaultTheme,
} from '@ampeersenergy/ampeers-ui-components';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { Row } from '../../../components/importer/import-flow';
import { useReadBookingsReportsQuery } from '../../../graphql-types';
import DownloadFileLink from '../../../components/downloadLink';
import { removeFileExtension } from '../../../helpers/formatStrings';

import { UploadBookingModal } from './UploadBookingModal';

const ImportSection = styled(Section)`
  max-width: 1100px;
`;

const Headline = styled(HeadlineComponent)`
  text-transform: none !important;
  letter-spacing: unset !important;
  margin-bottom: 1rem;
`;

const ImportButton = styled(Button)`
  margin: 1rem 0;
  padding: 0.5rem 3rem;
`;

const StatusDisplay = styled.div`
  display: flex;
  align-items: center;
`;

export default function ImportBookings() {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const { data, refetch } = useReadBookingsReportsQuery();

  React.useEffect(() => {
    // Trigger query to read the reports when the modal is closed
    refetch();
  }, [uploadModalOpen, refetch]);

  const columns: ColumnDefinition<any>[] = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: (row: Row) => removeFileExtension(row.values[0]),
      },
      {
        Header: 'Importdatum',
        accessor: (row: Row) => row.values[1],
        width: 100,
        type: 'date',
      },
      {
        Header: 'Status',
        accessor: (row: Row) => row.values[2],
        Cell: ({ cell }) => formatImportBookingStatus(cell.value),
      },
      {
        Header: 'Datei',
        accessor: (row: Row) => row.values[3],
        width: 80,
        Cell: ({ cell, row }) => (
          <DownloadFileLink url={cell.value} disabled={row.original.loading} />
        ),
      },
    ],
    [],
  );

  const rows: Row[] = data?.readBookingsReports.map((bookingReport) => {
    const { name, createdAt, status, url } = bookingReport;

    return {
      values: [name, createdAt, status, url],
    } as Row;
  }) as Row[];

  return (
    <>
      <Main>
        <ImportSection>
          {/* @ts-expect-error old Headline */}
          <Headline title>Import Buchungsstapel</Headline>
          <p>
            Lade hier Deinen Buchungsstapel hoch. Anschließen kannst du beginnen
            Deine Abrechnungen zu erstellen.
          </p>
          <ImportButton onClick={() => setUploadModalOpen(true)}>
            Import
          </ImportButton>
          <h3> Historie Buchungsstapel </h3>
          {rows && (
            <Table
              isLoading={false}
              data={rows}
              columns={columns}
              withPagination
              pageSize={10}
              initialState={{
                sortBy: [
                  {
                    id: 'Importdatum',
                    desc: true,
                  },
                ],
              }}
            />
          )}
        </ImportSection>
      </Main>
      {uploadModalOpen && <UploadBookingModal setIsOpen={setUploadModalOpen} />}
    </>
  );
}

function formatImportBookingStatus(status: string) {
  switch (status) {
    case 'success':
      return (
        <StatusDisplay>
          <Icons.Checkmark
            size={25}
            color={defaultTheme.palette.success.color}
          />
          Import erfolgt
        </StatusDisplay>
      );
    case 'failed':
      return (
        <StatusDisplay>
          <Icons.Wrong color={defaultTheme.palette.error.color} size={25} />
          Problem beim Import
        </StatusDisplay>
      );
    case 'loading':
      return (
        <StatusDisplay>
          <Icons.Progress size={25} /> Import vorgemerkt
        </StatusDisplay>
      );
    default:
      return <> Unbekannter Status </>;
  }
}
