import React, { useRef, useState } from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';

import { EditContainerProps } from '../../../../components/createFlow';
import { ReadTariffsDocument, TariffCreate } from '../../../../graphql-types';
import { yupUTCDate } from '../../../../helpers/yupUTCDate';
import GraphqlMultiStepForm from '../../../../components/graphql-form/multistepForm';
import numeral from '../../../../helpers/numeral';
import { DocTitle } from '../../../../components/docTitle';

import MasterDataForm from './MasterDataForm';
import DetailsForm from './DetailsForm';
import PricesheetForm from './PricesheetForm';

interface CreateTariffContainerProps extends EditContainerProps {
  isLoading?: boolean;
}

const createTariffTitle = 'Tarif anlegen';

export default function CreateTariffContainer({
  onSuccess,
  onAbort,
  isLoading,
  variables,
}: CreateTariffContainerProps) {
  const [isFixed, setFixed] = useState(false);
  const [isEnergyPriceMixed, setEnergyPriceMixed] = useState(true);
  const tariffFormRef: React.Ref<any> = useRef(null);

  const steps = [
    {
      title: 'Stammdaten',
      content: (
        <MasterDataForm
          setFixed={setFixed}
          isFixed={isFixed}
          ref={tariffFormRef}
        />
      ),
    },
    {
      title: 'Details',
      content: <DetailsForm ref={tariffFormRef} />,
    },
    {
      title: 'Preisblatt',
      content: (
        <PricesheetForm
          setEnergyPriceMixed={setEnergyPriceMixed}
          isEnergyPriceMixed={isEnergyPriceMixed}
          ref={tariffFormRef}
        />
      ),
    },
  ];

  return (
    <>
      <DocTitle titleParts={[createTariffTitle]} />
      <GraphqlMultiStepForm
        mutation="createTariff"
        title={createTariffTitle}
        steps={steps}
        onSuccess={onSuccess}
        onAbort={onAbort}
        defaultValues={{ tax: true, powerTax: true }}
        refetchQueries={[{ query: ReadTariffsDocument }]}
        isLoading={isLoading}
        validation={{
          validityStartDate: yupUTCDate.required(),
          ...(isFixed && {
            validityEndDate: yupUTCDate
              .when(
                'validityStartDate',
                (validityStartDate: string, schema: any) => {
                  if (!validityStartDate) {
                    return schema;
                  }
                  const minDate = DateTime.fromISO(validityStartDate)
                    .plus({ days: 1 })
                    .startOf('day')
                    .toJSDate();

                  return schema.min(
                    minDate,
                    `Ende der Gültigkeit darf nicht vor Beginn der Gültigkeit liegen`,
                  );
                },
              )
              .required(),
          }),
          nameInternal: yup
            .string()
            .test(
              'notOneOf',
              'Es gibt bereits einen Tarif mit diesem Namen',
              (_) => ![...variables.arrayOfIds].includes(_),
            )
            .required(),
          nameExternal: yup.string().required(),
          noticePeriod: {
            count: yup
              .string()
              .test(
                'lessThanMinimumduration',
                'Die Kündigungsfrist darf nicht länger sein als die Mindestlaufzeit',
                function test(
                  this: yup.TestContext,
                  value: string | undefined,
                ) {
                  const inputContext = this.options.context as TariffCreate;
                  const minimumDurationNumber = numeral(
                    inputContext.minimumDuration,
                  ).value();
                  const valueNumber = numeral(value).value()!;
                  const { period } = inputContext.noticePeriod;
                  if (
                    minimumDurationNumber === 0 ||
                    minimumDurationNumber === null ||
                    period === ''
                  ) {
                    return true;
                  }
                  const limit =
                    valueNumber *
                    (period === 'days'
                      ? 1 / 31
                      : period === 'months'
                      ? 1
                      : 1 / 4);

                  return limit <= minimumDurationNumber;
                },
              )
              .required(),
            period: yup.string().required(),
            to: yup.string().required(),
          },
          noticePeriodExtension: {
            count: yup
              .string()
              .test(
                'lessThanMinimumdurationExtension',
                'Die Kündigungsfrist darf nicht länger sein als die Mindestlaufzeit',
                function test(
                  this: yup.TestContext,
                  value: string | undefined,
                ) {
                  const inputContext = this.options.context as TariffCreate;
                  const minimumDurationExtensionNumber = numeral(
                    inputContext.minimumDurationExtension,
                  ).value();
                  const valueNumber = numeral(value).value()!;
                  const { period } = inputContext.noticePeriodExtension;
                  if (
                    minimumDurationExtensionNumber === 0 ||
                    minimumDurationExtensionNumber === null ||
                    period === ''
                  ) {
                    return true;
                  }
                  const limit =
                    valueNumber *
                    (period === 'days'
                      ? 1 / 31
                      : period === 'months'
                      ? 1
                      : 1 / 4);

                  return limit <= minimumDurationExtensionNumber;
                },
              )
              .required(),
            period: yup
              .string()
              .when('count', (count: number | string, schema: any) => {
                if (count === 0) {
                  return schema.notRequired();
                }
                return schema.required();
              }),
            to: yup
              .string()
              .when('count', (count: number | string, schema: any) => {
                if (count === 0) {
                  return schema.notRequired();
                }
                return schema.required();
              }),
          },
          priceSheet: {
            startDate: yupUTCDate
              .required()
              .test(
                'startDate',
                'Das Preisblatt muss im Gültigkeitsbereich des Tarifs liegen.',
                (value: any) => {
                  if (
                    !tariffFormRef?.current ||
                    !tariffFormRef?.current.getValue('validityStartDate')
                  ) {
                    return true;
                  }
                  const pricesheetStartDate = new Date(value).getTime();
                  const tariffStartDate = new Date(
                    tariffFormRef?.current.getValue('validityStartDate'),
                  ).getTime();
                  const tariffEndDate = new Date(
                    tariffFormRef?.current.getValue('validityEndDate'),
                  ).getTime();

                  if (!tariffFormRef?.current.getValue('validityEndDate')) {
                    return pricesheetStartDate >= tariffStartDate;
                  }
                  return (
                    pricesheetStartDate >= tariffStartDate &&
                    pricesheetStartDate <= tariffEndDate
                  );
                },
              ),
            ...(isEnergyPriceMixed && {
              energyPrice: yup.number().min(0).required(),
            }),
            ...(!isEnergyPriceMixed && {
              energyPriceLocal: yup.number().min(0).required(),
            }),
            ...(!isEnergyPriceMixed && {
              energyPriceResidual: yup.number().min(0).required(),
            }),
          },
        }}
      />
    </>
  );
}
