import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from '@ampeersenergy/ampeers-ui-components';

import { Table } from '../../../../components/new/Table';
import {
  ReadContractQuery,
  useReadMetadataQuery,
  useReadMetadataKeysQuery,
} from '../../../../graphql-types';
import { Flex, FlexRow, PaddedShadowBox } from '../../../../components';
import CreateFlow, {
  EditContainerProps,
} from '../../../../components/createFlow';
import ErrorMsg from '../../../../components/errorMsg';

import EditValue from './edit';
import AddKeyValueContainer from './addMetadataContainer';
import EndMessageEditMetadata from './endMessageEditMetadata';

interface MetadataProps extends EditContainerProps {
  contract?: ReadContractQuery['readContract'];
  isLoading: boolean;
}
const AlignCenter = styled.div`
  align-self: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;
function Metadata({ contract, isLoading }: MetadataProps) {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [oldValues, setOldValues] = useState();
  const performedActionState = useState<'edited' | 'deleted'>('edited');
  const {
    data: dataMetadata,
    error,
    loading,
  } = useReadMetadataQuery({
    variables: {
      contractLabel: contract?.label || '',
    },
    skip: !contract?.label,
  });
  const metadata = dataMetadata?.readMetadata ?? [];
  const { data: dataMetadataKeys } = useReadMetadataKeysQuery({
    variables: {
      contractLabel: contract?.label || '',
    },
    skip: !contract?.label,
  });
  const metadataKeys = dataMetadataKeys?.readMetadataKeys ?? [];

  return (
    <PaddedShadowBox>
      <Modal
        isOpen={createModalIsOpen}
        onRequestClose={() => setCreateModalIsOpen(false)}
        contentLabel="Add-OdooKeyValue-Modal"
        title="Metadaten hinzufügen"
      >
        <CreateFlow
          editContainer={AddKeyValueContainer}
          kind="Metadaten"
          onDone={() => setCreateModalIsOpen(false)}
          variables={{
            contractLabel: contract?.label,
            customerLabel: contract?.customer.label,
            metadataKeys,
            metadata,
          }}
        />
      </Modal>
      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={() => {
          setEditModalIsOpen(false);
          performedActionState[1]('edited');
        }}
        contentLabel="Add-OdooKeyValue-Modal"
        title="Metadaten editieren"
      >
        <CreateFlow
          editContainer={EditValue}
          kind="Metadaten"
          onDone={() => setEditModalIsOpen(false)}
          variables={{
            contractLabel: contract?.label,
            customerLabel: contract?.customer.label,
            oldValues,
            performedActionState,
          }}
          endMessageContainer={EndMessageEditMetadata}
        />
      </Modal>
      {error && <ErrorMsg error={error} />}
      <FlexRow>
        <Flex />
        <AlignCenter>
          <Button
            id="add-OdooKeyValue-btn"
            onClick={() => setCreateModalIsOpen(true)}
          >
            Werte hinzufügen
          </Button>
        </AlignCenter>
      </FlexRow>
      <Table
        data={metadata}
        columns={[
          {
            Header: 'Schlüssel',
            accessor: 'key',
          },
          {
            Header: 'Wert',
            accessor: 'value',
          },
        ]}
        filterKind="Metadaten"
        compact
        withBoxShadow
        withAlternatingRows
        onRowClick={(row: any) => {
          setOldValues(row.original);
          setEditModalIsOpen(true);
        }}
        isLoading={isLoading || loading}
      />
    </PaddedShadowBox>
  );
}

export default Metadata;
