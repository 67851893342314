import {
  Overlay,
  Stepper,
  useToasts,
} from '@ampeersenergy/ampeers-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { DeliveryPreference } from '../../graphql-types';
import { documentTypesKeyToI18n } from '../../helpers/formatStrings';
import { useTaskManager } from '../task-manager/TaskManagerProvider';

import { ContractPreviewStep } from './steps/contractPreviewStep';
import { PreviewStep } from './steps/previewStep';
import { SendStep } from './steps/sendStep';
import { InlineBlock } from './style';
import {
  CreationHandlerWithoutArgs,
  MultipleCreationHandler,
  PreviewHandler,
} from './types';

const StepperWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

export interface PreviewContentProps {
  previewDescription?: React.ReactNode;
  onNext: () => void;
  onPreview: CreationHandlerWithoutArgs;
}

function DefaultPreview({
  previewDescription,
  onNext,
  onPreview,
}: PreviewContentProps) {
  return (
    <>
      <div>{previewDescription}</div>
      <PreviewStep onNext={onNext} onPreview={onPreview} />
    </>
  );
}

interface CreateMultipleDocumentsModalProps<T> {
  type: string;
  onClose: () => void;
  contracts: T[];
  onPreview: PreviewHandler<T>;
  onCreate: MultipleCreationHandler<T>;
  renderItem: (contract: T) => React.ReactNode;
  overviewDescription?: React.ReactNode;
  userEmail: string;
  getId: (contract: T) => string;
  PreviewContent?: React.ComponentType<PreviewContentProps>;
}

export function CreateMultipleDocumentsModal<T>({
  type,
  onClose,
  contracts,
  onPreview,
  onCreate,
  renderItem,
  overviewDescription = 'Für die folgenden Verträge erstellen:',
  userEmail,
  getId,
  PreviewContent = DefaultPreview,
}: CreateMultipleDocumentsModalProps<T>) {
  const { addJobToTaskManager } = useTaskManager();
  const toast = useToasts();

  const [step, setStep] = React.useState(0);
  const [stepCompleted, setStepCompleted] = React.useState(0);

  const steps = [
    { label: 'Übersicht' },
    { label: 'Vorschau' },
    { label: 'Versandart' },
  ];

  React.useEffect(() => {
    if (step > stepCompleted) {
      setStepCompleted(step);
    }
  }, [step, stepCompleted]);

  const _onPreview = React.useCallback(
    (additional?: any) => {
      return onPreview(contracts[0], additional);
    },
    [contracts, onPreview],
  );

  const triggerJob = React.useCallback(
    async (_contracts: T[], deliveryPreference: DeliveryPreference) => {
      try {
        const result = await onCreate(_contracts, deliveryPreference!);
        if (result.isOk()) {
          addJobToTaskManager(result.value, type);
        } else {
          throw result.error;
        }
      } catch (error) {
        const message = error instanceof Error ? error.message : String(error);
        toast.error(
          `There was an error trying to create the documents: ${message}`,
        );
      }
    },
    [addJobToTaskManager, onCreate, toast, type],
  );

  const previewDescription = React.useMemo(
    () => (
      <>
        Vorschau für <InlineBlock>{renderItem(contracts[0])}</InlineBlock>
      </>
    ),
    [contracts, renderItem],
  );

  return (
    <Overlay
      isOpen
      onRequestClose={onClose}
      title={`${documentTypesKeyToI18n[type]} erstellen`}
      maxWidth="large"
    >
      <StepperWrapper>
        <Stepper
          steps={steps}
          onStepClick={(jumpTo) => {
            if (jumpTo <= stepCompleted && step < steps.length - 1) {
              setStep(jumpTo);
            }
          }}
          index={step}
        />
      </StepperWrapper>
      {step === 0 && (
        <ContractPreviewStep
          contracts={contracts}
          renderItem={renderItem}
          getId={getId}
          overviewDescription={overviewDescription}
          onNext={() => setStep(1)}
        />
      )}
      {step === 1 && (
        <PreviewContent
          previewDescription={previewDescription}
          onNext={() => setStep(2)}
          onPreview={_onPreview}
        />
      )}
      {step === 2 && (
        <SendStep
          onNext={(deliveryPreference: string) => {
            triggerJob(contracts, deliveryPreference as DeliveryPreference);
            onClose();
          }}
          userEmail={userEmail}
          disableB2CDelivery
        />
      )}
    </Overlay>
  );
}
