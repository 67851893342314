import * as React from 'react';
import styled from 'styled-components';
import { ButtonIcon, Icons, Hint } from '@ampeersenergy/ampeers-ui-components';
import { get } from 'lodash';
import { useDropzone, ErrorCode, Accept } from 'react-dropzone';
import { FormikErrors } from 'formik';

const FileUploadWrapper = styled.div<{ $hover?: boolean; $disabled?: boolean }>`
  display: flex;
  justify-content: center;

  border: 1px dashed ${(props) => (props.$hover ? '#979797' : '#C9C9C9')};
  padding: 12px;
  border-radius: 4px;
  opacity: ${(props) => (props.$disabled ? 0.5 : 1)};

  transform: ${(props) => (props.$hover ? 'scale(1.02)' : 'none')};
  transition: border-color, transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
`;

type FileUploadProps = {
  accept?: Accept;
  maxSize?: number;
  disabled?: boolean;
  errors?: FormikErrors<unknown>;
  name: string;
  onSelect: (file: File | null) => void;
};

export default function FileUpload({
  accept = {
    'application/pdf': ['.pdf'],
    'image/*': ['.jpeg', '.png', '.gif', 'jpeg'],
  },
  maxSize,
  disabled = false,
  errors,
  name,
  onSelect,
}: FileUploadProps) {
  const [fileError, setFileError] = React.useState('');

  const {
    getRootProps,
    getInputProps,
    inputRef,
    isDragActive,
    fileRejections,
  } = useDropzone({
    accept,
    maxSize,
    onDrop: (acceptedFiles) => {
      onSelect(acceptedFiles[0]);
    },
    noClick: true,
    noKeyboard: true,
    disabled,
    noDragEventsBubbling: true,
  });

  const openFileSelection = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  React.useEffect(() => {
    if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
      const errorCode = fileRejections[0].errors[0].code;
      switch (errorCode) {
        case ErrorCode.FileInvalidType:
          setFileError('Dieser Dateityp wird nicht unterstützt.');
          break;
        case ErrorCode.FileTooLarge:
          setFileError('Diese Datei ist zu groß.');
          break;
        case ErrorCode.TooManyFiles:
          setFileError('Es kann nur eine Datei hochgeladen werden.');
          break;
        default:
          break;
      }
    } else {
      setFileError('');
    }
  }, [fileRejections]);

  const hasError = get(errors, name) || fileError;

  return (
    <>
      <FileUploadWrapper
        {...getRootProps()}
        $hover={isDragActive}
        $disabled={disabled}
        data-testid={`${name}-file-upload-container`}
      >
        {/* @ts-expect-error old ButtonIcon */}
        <ButtonIcon
          icon={Icons.Upload}
          onClick={openFileSelection}
          disabled={disabled}
        >
          Datei auswählen...
        </ButtonIcon>
        <input name={name} {...getInputProps()} disabled={disabled} />
      </FileUploadWrapper>
      {hasError && !disabled && (
        <Hint color="warning" data-testid={`${name}-error`}>
          {fileError || get(errors, name)}
        </Hint>
      )}
    </>
  );
}
