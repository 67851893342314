import * as React from 'react';
import { Button } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';

import { useHasRole } from '../../../components/useHasRole';
import {
  AccountingRunWorkflowStep,
  AccountingRunWorkflowStepName,
  ActionOption,
  TransitionAction,
} from '../../../graphql-types';

import { useWorkflowStepsContext } from './WorkflowStepsProvider';

const ActionButtons = styled.div`
  display: flex;
  gap: 0.5rem;
  padding: 32px 0 0;
`;

function translateProceedStepActionLabel(
  name: AccountingRunWorkflowStepName,
  actionOption: ActionOption,
) {
  switch (name) {
    case AccountingRunWorkflowStepName.AutarkyStep:
    case AccountingRunWorkflowStepName.DownPaymentStartStep:
      return 'Speichern';
    default:
      break;
  }

  switch (actionOption) {
    case ActionOption.ProceedAndRetry:
      return 'Trotzdem fortfahren';
    case ActionOption.Proceed:
    default:
      return 'Nächster Schritt';
  }
}

interface StepActionProps {
  step: AccountingRunWorkflowStep;
  isLoading?: boolean;
  onAction: (action: TransitionAction) => void;
}

export function StepAction({ step, isLoading, onAction }: StepActionProps) {
  const { hasRole: operationRole, loading } = useHasRole('ae-operations');
  const { disableSaveButton } = useWorkflowStepsContext();

  if (step.action === ActionOption.None || loading) {
    return null;
  }

  const proceedButton = (
    <Button
      isLoading={isLoading}
      onClick={() => onAction(TransitionAction.Proceed)}
      key="proceed"
      disabled={disableSaveButton}
    >
      {translateProceedStepActionLabel(step.name, step.action)}
    </Button>
  );
  const retryButton = (
    <Button
      isLoading={isLoading}
      onClick={() => onAction(TransitionAction.Retry)}
      key="retry"
    >
      Schritt wiederholen
    </Button>
  );
  const opsRetryButton = operationRole ? (
    <Button
      isLoading={isLoading}
      onClick={() => onAction(TransitionAction.Retry)}
      key="opsRetry"
    >
      Schritt wiederholen
    </Button>
  ) : null;

  const renderActionButtons = () => {
    switch (step.action) {
      case ActionOption.Proceed:
        return proceedButton;
      case ActionOption.ProceedAndRetry:
        return [proceedButton, retryButton];
      case ActionOption.UserRetry:
        return retryButton;
      case ActionOption.OperationsRetry:
        return opsRetryButton;
      default:
        return null;
    }
  };

  return <ActionButtons>{renderActionButtons()}</ActionButtons>;
}
