import React from 'react';
import styled from 'styled-components';
import { FlexRow, IconProps } from '@ampeersenergy/ampeers-ui-components';

const NumberOfInvoicesText = styled.div`
  display: flex;
  gap: 12px;
`;

const NumberOfInvoicesWrapper = styled(FlexRow)`
  padding: 16px 0px 16px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  &:last-child {
    border-bottom: 0px;
  }
  & div {
    align-items: center;
  }
`;

export default function NumberOfInvoicesDisplay({
  text,
  invoiceNumber,
  icon,
  children,
}: {
  text: string;
  invoiceNumber: number;
  icon: (props: IconProps) => JSX.Element;
  children: JSX.Element;
}) {
  const Icon = icon;
  return (
    <NumberOfInvoicesWrapper key={text}>
      <NumberOfInvoicesText>
        <Icon size={20} />
        <span>{`${invoiceNumber} ${text}`}</span>
      </NumberOfInvoicesText>

      {children}
    </NumberOfInvoicesWrapper>
  );
}
