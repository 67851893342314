import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

/**
 * this uses https://www.npmjs.com/package/react-tooltip
 * Usage: add the attribute data-tip="hello world" to the
 * element you want a tooltip for and add
 * <ToolTip /> in the react tree
 */

const className = 'ae-tooltip' as string;

const StyledToolTip = styled(ReactTooltip).attrs({
  className,
})`
  &.${className} {
    font-size: 12px;
    font-family: 'Proxima Nova', sans-serif;
  }
`;

export function ToolTip() {
  return <StyledToolTip />;
}
