import React, { useState, useMemo, useEffect } from 'react';
import {
  Button,
  HistoricTabs,
  HistoricItem,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';

import {
  PaddedShadowBox,
  Relation,
  Entry,
  Flex,
  FlexRow,
} from '../../../../../components';
import {
  formatDate,
  formatEmgEndDates,
  formatTariff,
} from '../../../../../helpers/formatStrings';
import { ReadContractQuery, Tariff } from '../../../../../graphql-types';
import { isFinished } from '../../../../../helpers/utils';
import { TariffIcon } from '../../../../../components/icons';
import { StyledLink } from '../../../../../components/graphql-form/style';

import MainPanel from './mainPanel';

const LinkWrap = styled.div`
  margin-right: 5px;
  display: inline-block;
`;

interface ContractTariffsComponentProps {
  contract?: ReadContractQuery['readContract'];
  isLoading: boolean;
}

type HistoricTariffItem = Pick<Tariff, 'assignedTo' | 'assignedFrom'> &
  HistoricItem;

function ContractTariffsComponent({
  contract,
  isLoading,
}: ContractTariffsComponentProps) {
  const [isAddingNewItem, setIsAddingNewItem] = useState(false);
  const [index, setIndex] = useState(0);
  const [items, setItems] = useState<HistoricTariffItem[]>([]);
  const [date, setDate] = useState('');
  const location = useLocation();

  const sortedTariffs = useMemo(() => {
    if (!contract?.tariffs) return [];

    return contract?.tariffs
      .slice(0)
      .sort(
        (tariffA, tariffB) =>
          new Date(tariffB.assignedFrom).getTime() -
          new Date(tariffA.assignedFrom).getTime(),
      )
      .map((tariff) => {
        return {
          ...tariff,
          endDateFormatted: `${formatEmgEndDates(
            tariff?.assignedTo ? tariff.assignedTo : '',
            true,
            false,
          )} ${isFinished(tariff?.assignedTo) ? '(beendet)' : ''}`,
          startDateFormatted: formatDate(tariff?.assignedFrom),
        };
      });
  }, [contract?.tariffs]);

  useEffect(() => {
    setItems((prevItems) => {
      const newItem = isAddingNewItem
        ? prevItems.filter((item) => item.id === '-1')
        : [];

      return [
        ...newItem,
        ...sortedTariffs.map((sortedTariff) => ({
          ...sortedTariff,
          date: DateTime.fromISO(sortedTariff.assignedFrom).toISODate()!,
        })),
      ];
    });
  }, [isAddingNewItem, sortedTariffs]);

  useEffect(() => {
    if (isAddingNewItem) {
      setItems((_items: any) => {
        if (_items[0]?.id === '-1') {
          const itemsWithoutNewItem = _items.filter(
            (item: any, _index: number) => _index !== 0,
          );
          return [{ date, id: '-1' }, ...itemsWithoutNewItem];
        }
        return [{ date, id: '-1' }, ..._items];
      });
      setIndex(0);
    } else {
      setItems((_items: any) => {
        if (_items[0]?.id === '-1') {
          return _items.filter((item: any, _index: number) => _index !== 0);
        }
        return _items;
      });
    }
  }, [isAddingNewItem, date]);

  return (
    <PaddedShadowBox>
      <HistoricTabs
        items={items}
        isLoading={isLoading}
        title="Vertrag"
        renderActions={() => (
          <Button
            disabled={isAddingNewItem || /_ENDED$/.test(contract?.status ?? '')}
            onClick={() => setIsAddingNewItem(true)}
            data-testid="ContractTariffsComponent-edit"
          >
            Bearbeiten
          </Button>
        )}
        index={index}
        onItemSelect={(item) => {
          if (item.id !== '-1' && isAddingNewItem) {
            const clickedItemId = items.findIndex((i: any) => i.id === item.id);

            setIsAddingNewItem(false);
            setIndex(clickedItemId - 1);
          }
        }}
      >
        {(item: HistoricTariffItem) => {
          const tariff = sortedTariffs.find(
            (_tariff) => _tariff.id === item.id,
          );
          return item.id !== '-1' ? (
            <>
              <FlexRow>
                <Flex>
                  <Entry title="Beginn Gültigkeit">
                    {tariff?.startDateFormatted}
                  </Entry>
                </Flex>
                <Flex>
                  <Entry title="Ende Gültigkeit">
                    {tariff?.endDateFormatted}
                  </Entry>
                </Flex>
              </FlexRow>
              <FlexRow>
                <Flex>
                  <Entry title="Tarif">
                    <LinkWrap>
                      {tariff ? (
                        <StyledLink
                          to={`${location.pathname}/tariff/${tariff?.tariffId}`}
                        >
                          <Relation
                            icon={TariffIcon}
                            title={formatTariff(tariff)}
                          />
                        </StyledLink>
                      ) : (
                        '--'
                      )}
                    </LinkWrap>
                  </Entry>
                </Flex>
              </FlexRow>
            </>
          ) : (
            <MainPanel
              key={item.id}
              setDate={setDate}
              latestStartDate={sortedTariffs[0].assignedFrom}
              meterReadingDate={date}
              contract={contract}
              currentTariff={{
                tariffId: sortedTariffs[0].tariffId,
                startDate: sortedTariffs[0].assignedFrom,
              }}
              onSuccess={() => setIsAddingNewItem(false)}
              onAbort={() => setIsAddingNewItem(false)}
            />
          );
        }}
      </HistoricTabs>
    </PaddedShadowBox>
  );
}

export default ContractTariffsComponent;
