/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Filter,
  FilterBar,
  FlexRow,
  ColumnDefinition,
} from '@ampeersenergy/ampeers-ui-components';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Table } from '../../components/new/Table';
import { ContractExcerpt, PlantDetailExtendedQuery } from '../../graphql-types';
import { SubTitle } from '../../components';
import {
  formatEmgEndDates,
  formatString,
  formatContractStatus,
} from '../../helpers/formatStrings';
import CreateOverlay from '../contract/create/createOverlay';

export const Flex = styled.div`
  flex: 1;
`;

const AlignCenter = styled.div`
  align-self: center;
`;

const columns: ColumnDefinition<any>[] = [
  {
    Header: 'Typ',
    accessor: 'type',
  },
  {
    Header: 'Betreiber',
    accessor: 'name',
  },
  {
    Header: 'KundenNr.',
    accessor: 'customerLabel',
  },
  {
    Header: 'VertragsNr.',
    accessor: 'label',
  },
  {
    Header: 'ZählerNr.',
    accessor: 'meterNumber',
  },
  {
    Header: 'Lieferbeginn',
    accessor: 'startDate',
    type: 'date',
  },
  {
    Header: 'Lieferende',
    accessor: 'endDate',
    type: 'date',
    Cell: ({ value }) => formatEmgEndDates(value, true, false),
  },
  {
    Header: 'Vertragsstatus',
    accessor: 'contractStatus',
  },
];

const widths: { [key: string]: number | string } = {
  'ZählerNr.': '8%',
  Zählerplatz: '10%',
  MeLo: '20%',
  Mieter: '16%',
  Betreiber: '20%',
  Zählertyp: '8%',
  'VertragsNr.': '13%',
};

const C = (Header: string, accessor: string) => ({
  Header,
  accessor,
  ...(widths[Header] && { width: widths[Header] }),
});

const formatType = (
  meterType: string,
  isOperator: boolean,
  electricityGenerationOrGasType?: string | null,
  withoutContract?: boolean,
): string => {
  if (meterType === 'MSO-H') {
    if (withoutContract) {
      return 'Summenzähler';
    }
    if (isOperator) {
      return 'Summenzähler Lieferung';
    }
    return 'Summenzähler Bezug';
  }
  if (meterType === 'MSO-E') {
    if (electricityGenerationOrGasType === 'EEG_SOS') {
      return 'PV';
    }
    if (electricityGenerationOrGasType === 'KWK') {
      return 'BHKW';
    }
    return 'Erzeugung';
  }
  if (meterType === 'MSO-L') {
    return 'Ladesäule';
  }
  if (meterType === 'MSO-P') {
    return 'Wärmepumpe';
  }
  if (meterType === 'MSO-A') {
    return 'Allgemeinstrom';
  }
  if (meterType === 'MSO-K') {
    return 'Kaskade';
  }

  return '--';
};

function GenerationPlantList({
  data,
  isLoading,
}: {
  data: PlantDetailExtendedQuery | undefined;
  isLoading: boolean;
}) {
  const location = useLocation();
  const [createContractOverlayVisible, setCreateContractOverlayVisible] =
    useState(false);
  const [
    createHeatContractOverlayVisible,
    setCreateHeatContractOverlayVisible,
  ] = useState(false);
  const [createOverlayVisible, setCreateOverlayVisible] = useState(false);
  const [createContractValues, setCreateContractValues] = useState({});
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const navigate = useNavigate();
  const showHeatContracts = data?.readPlant?.measurementConcept === 'MK_AT_1';

  const withoutColumns: ColumnDefinition<any>[] = useMemo(
    () => [
      columns[0],
      columns[4],
      {
        Header: 'Vertrag',
        accessor: '',
        width: 200,
        disableSortBy: true,
        Cell: (props: any) => (
          <Button
            onClick={() => {
              setCreateOverlayVisible(true);
              setCreateContractValues({
                contractMeter: { meter: props.row.original },
              });
            }}
          >
            Vertrag anlegen
          </Button>
        ),
      },
    ],
    [setCreateOverlayVisible],
  );

  const onRowClick = (row: any) => {
    const entry = row.original as ContractExcerpt;

    navigate(`${location.pathname}/contract/${entry.id}`);
  };

  const metersWithoutContracts = useMemo(() => {
    if (data?.readPlant) {
      return data.readPlant.measurementConceptContractsInSetup.map((row) => ({
        ...row,
        type: formatType(row.meterType, row.isOperator, undefined, true),
      }));
    }

    return [];
  }, [data]);

  const tableData = useMemo(() => {
    if (data?.readPlant) {
      const formattedRows = data?.readPlant?.buildingContracts.map(
        (row: any) => ({
          ...row,
          type: formatType(
            row.meterType,
            row.isOperator,
            row?.electricityGenerationOrGasType,
          ),
          name: formatString(row.name),
          customerLabel: formatString(row.customerLabel),
          label: formatString(row.label),
          contractStatus: formatContractStatus(row.contractStatus).title,
        }),
      );

      const heatContractRows = formattedRows.filter(
        (row) => row.contractType === 'AUTARKY_HEAT',
      );
      const normalContractRows = formattedRows.filter(
        (row) => row.contractType !== 'AUTARKY_HEAT',
      );
      return {
        normalContractRows,
        heatContractRows,
      };
    }
    return {
      normalContractRows: [],
      heatContractRows: [],
    };
  }, [data]);

  return (
    <>
      <FilterBar
        filters={filters}
        columns={columns.map((column) =>
          C(column.Header, column.accessor as any),
        )}
        setAllFilters={setFilters}
        kind="Anlagen"
      />
      {metersWithoutContracts.length !== 0 && (
        <>
          <SubTitle>Ohne Vertrag</SubTitle>
          <Table
            data={metersWithoutContracts}
            columns={withoutColumns}
            filterState={filters}
            isLoading={isLoading}
            compact
            withAlternatingRows
            withBoxShadow
            withPagination
          />
        </>
      )}
      <FlexRow>
        <Flex>
          <SubTitle>Verträge</SubTitle>
        </Flex>
        <AlignCenter>
          <Button onClick={() => setCreateContractOverlayVisible(true)}>
            Vertrag anlegen
          </Button>
        </AlignCenter>
      </FlexRow>
      <Table
        data={tableData.normalContractRows}
        columns={columns}
        onRowClick={onRowClick}
        filterState={filters}
        isLoading={isLoading}
        compact
        withAlternatingRows
      />
      {showHeatContracts && (
        <>
          <FlexRow>
            <Flex>
              <SubTitle>Wärmeverträge</SubTitle>
            </Flex>
            <AlignCenter>
              <Button onClick={() => setCreateHeatContractOverlayVisible(true)}>
                Wärmevertrag anlegen
              </Button>
            </AlignCenter>
          </FlexRow>
          <Table
            data={tableData.heatContractRows}
            columns={columns}
            onRowClick={onRowClick}
            filterState={filters}
            isLoading={isLoading}
            compact
            withAlternatingRows
          />
        </>
      )}
      {data?.readPlant && createOverlayVisible && (
        <CreateOverlay
          plant={data.readPlant}
          onClose={() => setCreateOverlayVisible(false)}
          kind="operator"
          isVisible={createOverlayVisible}
          defaultValues={createContractValues}
        />
      )}
      {data?.readPlant && createContractOverlayVisible && (
        <CreateOverlay
          plant={data.readPlant}
          onClose={() => setCreateContractOverlayVisible(false)}
          kind="generationPlant"
          isVisible={createContractOverlayVisible}
          defaultValues={{
            customer: {
              isOperator: false,
            },
          }}
        />
      )}
      {showHeatContracts &&
        data?.readPlant &&
        createHeatContractOverlayVisible && (
          <CreateOverlay
            plant={data.readPlant}
            onClose={() => setCreateHeatContractOverlayVisible(false)}
            kind="generationPlant"
            isVisible={createHeatContractOverlayVisible}
            defaultValues={{
              contractType: 'AUTARKY_HEAT',
              customer: {
                isOperator: false,
              },
            }}
          />
        )}
    </>
  );
}

export default GenerationPlantList;
