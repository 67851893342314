import styled from 'styled-components';
import { Button, FlexRow } from '@ampeersenergy/ampeers-ui-components';

export const FilterBarWrapper = styled.div`
  background: ${(props) => props.theme.palette.background};
  display: flex;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  align-items: center;
  margin-bottom: 10px;
  height: 50px;
  padding-left: 15px;

  .react-autosuggest__container {
    flex: 1;
    height: 40px;
    padding-left: 5px;

    input {
      border: none;
      width: 100%;
      outline: 0;
      height: 40px;
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    background: ${(props) => props.theme.palette.background};
    border: 1px solid ${(props) => props.theme.palette.border};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    z-index: 2;

    ul {
      list-style: none;
      margin: 0;
      padding: 0px;
    }

    li {
      border-bottom: 1px solid ${(props) => props.theme.palette.border};
      padding: 8px 15px;
      cursor: pointer;

      &:last-child {
        border: none;
      }

      &.react-autosuggest__suggestion--highlighted {
        background: #dbffd4;
      }
    }
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.palette.textMuted};
  letter-spacing: 1.29px;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 10px;
`;

export const ItemList = styled.div`
  background: ${(props) => props.theme.palette.backgroundMuted};
  margin-left: 10px;
`;

export const Item = styled.div`
  border: 1px solid ${(props) => props.theme.palette.border};
  border-radius: 4px;
  display: inline-flex;
  margin-right: 2px;
  align-items: center;
  padding: 0px 0px 0px 8px;
  background: ${(props) => props.theme.palette.background};
`;

export const ItemLabel = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => props.theme.primaryColor};
  letter-spacing: 1.29px;
  text-transform: uppercase;
  padding-top: 1px;
`;

export const ItemValue = styled.span`
  font-size: 14px;
  color: ${(props) => props.theme.palette.textMuted};
  letter-spacing: 0;
  margin-left: 5px;
`;

export const TableStyled = styled.div<{ $disabled?: boolean }>`
  position: relative;
  &.hasLink tbody tr:hover {
    cursor: pointer;
    background: ${(props) => props.theme.secondaryColor} !important;
  }

  .wrapper {
    overflow: hidden;
    width: 100%;
  }

  .withHeader {
    padding: 32px 34px;
    width: unset;
    border: none !important;
  }

  table {
    ${(props) => (props.$disabled ? `opacity: 0.6;` : ``)}
    background: ${(props) => props.theme.palette.background};
    border-spacing: 0;
    font-size: 14px;
    width: 100%;

    thead tr:first-child {
      background: ${(props) => props.theme.palette.background};
    }

    th {
      font-weight: 700;
      font-size: 12px;
      color: ${(props) => props.theme.palette.text};
      letter-spacing: 1.29px;
      text-transform: uppercase;
      text-align: left;
      border-bottom: 1px solid ${(props) => props.theme.palette.border};

      &.canSort {
        cursor: pointer;

        &:hover {
          color: ${(props) => props.theme.primaryColor};
        }
      }

      &.sorted {
        color: ${(props) => props.theme.primaryColor};
      }

      &.noBorder {
        :last-child {
          border-right: 0;
        }
      }
    }

    tr {
      overflow: hidden;
    }

    tr:first-child th {
      border-top: 1px solid ${(props) => props.theme.palette.border};
    }

    tr td:first-child,
    tr th:first-child {
      border-left: 1px solid ${(props) => props.theme.palette.border};
    }

    th,
    td {
      margin: 0;
      padding: 12px;
      border-right: 1px solid ${(props) => props.theme.palette.border};

      &.compact {
        padding: 8px;
      }

      &.selection {
        padding: 2px;
      }
    }

    .tooltip {
      max-width: 300px !important;
    }

    td.newRow,
    th.newColumn {
      text-align: start;
      padding: 0;
      color: ${(props) => props.theme.palette.textMuted};

      button {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 4px;
        background: unset;
        border: unset;
        outline: unset;
        width: 100%;
        padding: 0;
        font-size: 15px;
      }

      & > button:hover {
        background-color: ${(props) => props.theme.palette.backgroundMuted};
      }

      & > button:active {
        background-color: ${(props) => props.theme.secondaryColor};
      }

      svg {
        transform: rotate(45deg) scale(0.8);
      }
    }

    td.newRow {
      border: 0 !important;

      button {
        margin-top: 4px;
      }
    }

    th.newColumn {
      border: 0 !important;
      width: 30px;

      button {
        margin-left: 4px;
      }
    }
  }
`;

export const TableStyledWithBoxShadow = styled(TableStyled)`
  .wrapper {
    background: ${(props) => props.theme.palette.background};
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
  }
`;

export const Row = styled.tr`
  td {
    border-bottom: 1px solid ${(props) => props.theme.palette.border};
  }
  a {
    display: block;
    color: ${(props) => props.theme.palette.textMuted};
  }
  &.noBorder {
    td {
      :last-child {
        border-right: 0;
      }
    }
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  &.selected {
    background: ${(props) => props.theme.secondaryColor} !important;
  }
`;

export const AlternatingRow = styled(Row)`
  :nth-child(2n - 1) {
    background: ${(props) => props.theme.palette.backgroundMuted};
  }

  &:not(:last-child) {
    td {
      border-bottom: 0px;
    }
  }
`;

export const TdStyled = styled.td`
  .content-wrapper {
    display: flex;
    justify-content: space-between;
  }
`;

export const EmptyResult = styled.div`
  padding: 50px 0px;
  text-align: center;
  color: ${(props) => props.theme.palette.textMuted};
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.palette.border};
  border-top: 0px;
  background: ${(props) => props.theme.palette.background};
`;

export const RemoveButton = styled(Button)`
  padding: 6px 3px 6px 3px !important;
  box-shadow: none !important;
  border: none !important;
`;

export const PaginationWrapper = styled.div`
  position: relative;
  font-weight: 400;
  font-size: 14px;
  padding: 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 10px;

  button {
    padding: 8px !important;
  }
`;

export const PaginationText = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

export const CheckboxLabel = styled.label`
  padding: 0.5rem;
  margin: -0.5rem;
  display: block;
  text-align: center;
  min-height: 100%;
`;

export const TableActionBar = styled(FlexRow)`
  margin-top: 27px;
  margin-bottom: 20px;
  justify-content: flex-end;
`;

export const HeaderSortingWrapper = styled.span`
  display: flex;
  align-items: center;
`;
