import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useFormikContext } from 'formik';

import { StyledLabel } from '../pages/contract/sharedForms';

import { useGraphqlForm } from './graphql-form/hooks/useGraphqlForm';
import { TooltipInfo } from './TooltipInfo';

import { Label } from '.';

interface BooleanState {
  value: boolean;
  setValue: (value: boolean) => void;
}

const OptionalCustomValueWrap = styled.div``;

const Item = styled.div``;

interface OptionalCustomValueProps {
  label: string;
  options: string[];
  children: React.ReactNode;
  managedState?: [BooleanState['value'], BooleanState['setValue']];
  'test-id'?: string;
  fieldPaths: string[];
  tooltipText?: string;
}

export function OptionalCustomValue({
  label,
  managedState,
  'test-id': testId,
  options,
  fieldPaths,
  tooltipText,
  children,
}: OptionalCustomValueProps) {
  const id = `option-${label}`;
  const selfManagedState = useState(true);
  const [autoGenerateValue, setAutoGenerateValue] =
    managedState ?? selfManagedState;
  const [shouldResetValues, setResetValues] = useState(true);
  const { setFieldValue, initialValues, getFieldProps } = useFormikContext();
  const { isLoading, formVariables } = useGraphqlForm();

  const generatedValue = useMemo(
    () => fieldPaths.map((path) => formVariables[path]),
    [fieldPaths, formVariables],
  );

  const shouldEmptyField = useMemo(
    () =>
      generatedValue.every(
        (value, i) => value === getFieldProps(fieldPaths[i]).value,
      ),
    [fieldPaths, generatedValue],
  );
  useEffect(() => {
    if (shouldResetValues) {
      if (autoGenerateValue) {
        for (let index = 0; index < fieldPaths.length; index++) {
          setFieldValue(fieldPaths[index], generatedValue[index], true);
        }
      } else if (shouldEmptyField) {
        for (let index = 0; index < fieldPaths.length; index++) {
          setFieldValue(fieldPaths[index], '', false);
        }
      }
      setResetValues(false);
    }
  }, [
    isLoading,
    setFieldValue,
    autoGenerateValue,
    initialValues,
    generatedValue,
    shouldEmptyField,
    fieldPaths,
    shouldResetValues,
  ]);

  return (
    <OptionalCustomValueWrap>
      <Label>
        {label}
        {tooltipText && (
          <TooltipInfo id={`${label}ToolTip`} text={tooltipText} />
        )}
      </Label>
      <Item>
        <input
          type="radio"
          data-testid={`${testId ?? id}-true`}
          id={`${id}-true`}
          checked={autoGenerateValue}
          onClick={() => {
            setAutoGenerateValue(true);
            setResetValues(true);
          }}
          readOnly
        />
        <StyledLabel htmlFor={`${id}-true`}>{options[0]}</StyledLabel>
      </Item>
      <Item>
        <input
          type="radio"
          data-testid={`${testId ?? id}-false`}
          id={`${id}-false`}
          checked={!autoGenerateValue}
          onClick={() => {
            setAutoGenerateValue(false);
            setResetValues(true);
          }}
          readOnly
        />
        <StyledLabel htmlFor={`${id}-false`}>{options[1]}</StyledLabel>
        {children}
      </Item>
    </OptionalCustomValueWrap>
  );
}
