import React from 'react';
import { DialogProvider } from '@ampeersenergy/ampeers-ui-components';
import { useParams, useLocation, useResolvedPath } from 'react-router-dom';

import { ErrorMsg, SubTitle } from '../../../components';
import { NestedPage } from '../../../components/nestedPage';
import { Metering, useReadMeterQuery } from '../../../graphql-types';
import { LOADING_STR } from '../../../helpers/formatStrings';
import { LinkedTab, LinkedTabs } from '../../../components/new/LinkedTabs';

import { MeterInfo } from './pages/meterInfo';
import { MeterReadings } from './pages/readings';
import { MeterHistory } from './pages/meterHistory';

export function MeterDetailPage() {
  const { meterId, showObis } = useParams<{
    meterId: string;
    showObis?: string;
  }>();
  const location = useLocation();
  const basePath = useResolvedPath('').pathname;

  const levelsUpToParent =
    location.pathname.includes('tenant/contract') ||
    location.pathname.includes('building/contract')
      ? 3
      : 2;

  const { data, error, loading } = useReadMeterQuery({
    variables: {
      id: meterId!,
    },
  });

  if (error) {
    return (
      <NestedPage levelsUpToParent={levelsUpToParent}>
        <SubTitle>{meterId}</SubTitle>
        <ErrorMsg message={String(error)} />
      </NestedPage>
    );
  }

  return (
    <NestedPage levelsUpToParent={levelsUpToParent}>
      <SubTitle>
        {loading ? LOADING_STR : null}
        {data?.readMeter?.meterPlace ? `${data.readMeter.meterPlace}, ` : ''}
        {data?.readMeter?.meterNumber}
      </SubTitle>
      <LinkedTabs basePath={basePath}>
        <LinkedTab title="Zählerinformationen" path="meterInformation">
          <DialogProvider>
            <MeterInfo meter={data?.readMeter} loading={loading} />
          </DialogProvider>
        </LinkedTab>
        <LinkedTab
          title={
            data?.readMeter
              ? data.readMeter.metering === Metering.Rlm
                ? 'Verbrauchswerte'
                : 'Zählerstände'
              : ''
          }
          path="meterReading"
        >
          <MeterReadings
            meter={data?.readMeter}
            loading={loading}
            showObis={showObis !== 'false'}
          />
        </LinkedTab>
        <LinkedTab title="Vertrag" path="meterHistory">
          {meterId && <MeterHistory meterId={meterId} />}
        </LinkedTab>
      </LinkedTabs>
    </NestedPage>
  );
}
