/* eslint-disable react/no-is-mounted */

import { isNumber } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

/**
 * Class that implements a simple progress tracking functionality
 *
 * When starting the process, a timer gets triggered that executes the onInterval function
 * at every interval defined by the throttle (in milliseconds). The onInterval function
 * updates the elapsed time and calculates the progress percentage based on an inverse
 * tangent function. This means that progress escalates faster in the beginning and slows
 * down once it gets closer to 100%. The expected duration adjusts how steep this progression
 * is based on the expected time for the actual async function to complete.
 *
 * For example, if an API call takes roughly 50 seconds to, we can start a progress tracker
 * with duration of 50 seconds. To increase how often the value gets updated, we can decrese
 * the throttle to 50 miliseconds. Take in mind that this will result in more renders.
 *
 * Based on
 * https://github.com/piercus/fake-progress/blob/master/index.js
 * https://github.com/nuxt/nuxt/blob/main/packages/nuxt/src/app/composables/loading-indicator.ts
 */
class ProgressTracker {
  private intervalId: NodeJS.Timer | undefined;

  private time = 0;

  private progress = 0;

  // total duration of expected progress
  // increase for slower progress
  duration: number;

  // update interval
  // decrease for more frequent progress updates
  throttle: number;

  private onChange?: (progress: number, time: number) => void;

  constructor(
    onChange?: (progress: number, time: number) => void,
    duration = 10000,
    throttle = 100,
  ) {
    this.time = 0;
    this.progress = 0;
    this.duration = duration;
    this.throttle = throttle;
    this.onChange = onChange;
  }

  onInterval() {
    const elapsed = this.time + this.throttle;
    const completionPercentage = (elapsed / this.duration) * 100;
    /**
     * y = p * arctan(x / q)
     * where
     * y is the progress in [0,1], representing the percentage of progress for any given time
     * x is the completion percentage in [0,100] calculated by the proportion between elapsed time and total duration
     * q is a scaling parameter that adjusts how steep the curve. It is set to 50 which adjusts the curve so that it hits 50% progress by 50%
     * of the completion percentage
     * p is a scaling parameter composed of two components:
     *   (2/pi): the normal arctan function approaches pi/2 when x tends to infinity, so we normalize this to 1 by multiplying the result by 2/pi
     *   (1.4): by adjusting the curve so that it hits 50% progress by 50% of the elapsed time, we alter it so that it reaches 70% by 100% of the
     *   elapsed time. This has the negative effect that an api which is estimated to take 10 seconds will show a 70% completion percentage when
     *   10 seconds have passed. By multiplying by 1.4, we guarantee that it reaches 100% by 100% completion percentage. This adjusment also makes
     *   it so that the progress hits 70% by 50% of the completion percentage.
     */
    const newProgress =
      1.4 * (2 / Math.PI) * Math.atan(completionPercentage / 50);

    this.time = elapsed;
    this.progress = Math.max(0, Math.min(0.99, newProgress));

    this.onChange?.(this.progress, this.time);
  }

  start() {
    this.time = 0;
    this.progress = 0;
    this.intervalId = setInterval(this.onInterval.bind(this), this.throttle);
    this.onChange?.(this.progress, this.time);
  }

  stop() {
    if (this.intervalId && isNumber(this.intervalId)) {
      clearInterval(this.intervalId);
    }
    this.intervalId = undefined;
    this.progress = 1;
    this.onChange?.(this.progress, this.time);
  }
}

const AnimateSvg = styled.svg`
  .animate {
    transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
`;

const CIRCLE_COLORS = {
  background: '#F9F9F9',
  progress: '#13238E',
};

export interface ProgressLoaderProps {
  radius?: number;
  stroke?: number;
  durationInSeconds?: number;
  throttleInMiliSeconds?: number;
  finished?: boolean;
}

export const ProgressLoader: React.FC<ProgressLoaderProps> = ({
  radius = 60,
  stroke = 8,
  durationInSeconds,
  throttleInMiliSeconds,
  finished = false,
}) => {
  const theme = useTheme();

  const [progress, setProgress] = useState(0);

  const tracker = useMemo(
    () =>
      new ProgressTracker(
        (p) => setProgress(+(p * 100).toFixed()),
        durationInSeconds && durationInSeconds * 1000,
        throttleInMiliSeconds,
      ),
    // only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    tracker.start();

    // stop on unmount
    return () => tracker.stop();
    // only run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (finished) {
      tracker.stop();
    }
    // only run when finished changes to true
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished]);

  const circumference = useMemo(() => radius * 2 * Math.PI, [radius]);

  const boxCenter = useMemo(() => radius + stroke, [radius, stroke]);
  const boxSize = useMemo(() => radius * 2 + stroke * 2, [radius, stroke]);

  const strokeDashOffset = useMemo(
    () => circumference - (progress / 100) * circumference,
    [circumference, progress],
  );

  return (
    <AnimateSvg height={boxSize} width={boxSize}>
      <circle
        key="progress-loader-background"
        stroke={CIRCLE_COLORS.background}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        r={radius}
        cx={boxCenter}
        cy={boxCenter}
        strokeLinecap="round"
      />
      <circle
        key="progress-loader-progress"
        className="animate"
        stroke={CIRCLE_COLORS.progress}
        fill="transparent"
        strokeWidth={stroke}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset: strokeDashOffset }}
        r={radius}
        cx={boxCenter}
        cy={boxCenter}
        strokeLinecap="round"
      />

      <text
        className="text"
        x={boxCenter}
        y={boxCenter}
        dominantBaseline="middle"
        textAnchor="middle"
        fill={theme.palette.text}
        style={{
          fontSize: '24px',
          fontWeight: 600,
        }}
      >
        {progress}%
      </text>
    </AnimateSvg>
  );
};
