import * as React from 'react';
import { Accept } from 'react-dropzone';

export const humanReadableSize = (_size: number) => {
  const units = ['B', 'KB', 'MB', 'GB'];
  let i = 0;
  let size = _size;
  while (size > 1024) {
    if (i < units.length - 1) {
      size /= 1024;
      i += 1;
    } else {
      break;
    }
  }
  const unit =
    i >= 0 && i <= units.length
      ? units[i]
      : units[units.length > 0 ? units.length - 1 : 0];
  return `${size.toFixed(0)} ${unit}`;
};

export const listAcceptedTypes = (accept: Accept) => {
  const types = accept.keys
    ?.map((type) => {
      const t = type.trim();
      switch (t) {
        case 'image/jpeg':
          return 'JPEG';
        case 'image/png':
          return 'PNG';
        case 'image/*':
          return 'Bilder';
        case 'application/pdf':
          return 'PDF';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'DOCX';
        default:
          return undefined;
      }
    })
    .filter((t) => t !== undefined);

  if (!types || types.length === 0) {
    return null;
  }

  return (
    <ul>
      {types.map((type) => (
        <li key={type}>{type}</li>
      ))}
    </ul>
  );
};

export function getMimeExtensionMap(mimeTypes: string[]): Accept {
  const mimeToExtensions: Accept = {
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    // Add more MIME types and extensions here if needed
  };

  const result: Accept = {};

  mimeTypes.forEach((mimeType) => {
    if (mimeToExtensions[mimeType]) {
      result[mimeType] = mimeToExtensions[mimeType];
    }
  });

  return result;
}
