import React from 'react';
import { PaddedShadowBox } from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import ContentLoader from 'react-content-loader';

import { InvoicePeriod, Periods } from './dashboardAccountingTypes';
import {
  ClosingInvoicePeriodRow,
  StandardInvoicePeriodRow,
  UnfinishedPeriodRow,
  YearlyInvoicePeriodRow,
} from './PeriodRow';

const Wrapper = styled(PaddedShadowBox)`
  width: 100%;
  padding: 16px 24px;
`;

const MonthlyCardTitle = styled.h3`
  font-size: 18px;
  margin-top: 0;
`;

export default function InvoiceMonthCard({
  period,
}: {
  period: InvoicePeriod;
}) {
  const monthlyInvoices = period.standardAccounting.monthlySettlement;
  const quarterlyInvoices = period.standardAccounting.quarterlySettlement;
  const halfYearlyInvoices = period.standardAccounting.halfYearlySettlement;
  const yearlyInvoices = period.standardAccounting.yearlySettlement;
  const finalInvoices = period.closingAccounting;

  const unfinishedMonthlyInvoices =
    period.unfinishedStandardAccounting?.monthlySettlement;
  const unfinishedQuarterlyInvoices =
    period.unfinishedStandardAccounting?.quarterlySettlement;
  const unfinishedHalfYearlyInvoices =
    period.unfinishedStandardAccounting?.halfYearlySettlement;
  const unfinishedYearlyInvoices =
    period.unfinishedStandardAccounting?.yearlySettlement;
  const unfinishedFinalInvoices = period.unfinishedClosingAccounting;

  // array with all the unfinished invoices
  const unfinishedInvoices = [
    ...(unfinishedMonthlyInvoices || []),
    ...(unfinishedQuarterlyInvoices || []),
    ...(unfinishedHalfYearlyInvoices || []),
    ...(unfinishedYearlyInvoices || []),
    ...(unfinishedFinalInvoices || []),
  ];

  if (
    monthlyInvoices.length === 0 &&
    quarterlyInvoices.length === 0 &&
    halfYearlyInvoices.length === 0 &&
    yearlyInvoices.length === 0 &&
    finalInvoices.length === 0 &&
    unfinishedInvoices.length === 0
  ) {
    return null;
  }

  const periodDate = DateTime.fromISO(period.start);
  return (
    <Wrapper>
      <MonthlyCardTitle>
        {periodDate.setLocale('de').toFormat('MMMM')} {periodDate.toFormat('y')}
      </MonthlyCardTitle>
      <StandardInvoicePeriodRow
        invoices={monthlyInvoices}
        invoiceCycleType={Periods.monthly}
        period={period}
      />
      <StandardInvoicePeriodRow
        invoices={quarterlyInvoices}
        invoiceCycleType={Periods.quarterly}
        period={period}
      />
      <StandardInvoicePeriodRow
        invoices={halfYearlyInvoices}
        invoiceCycleType={Periods.halfyearly}
        period={period}
      />
      <YearlyInvoicePeriodRow
        invoices={yearlyInvoices}
        invoiceCycleType={Periods.yearly}
        period={period}
      />
      <ClosingInvoicePeriodRow
        invoices={finalInvoices}
        invoiceCycleType={Periods.final}
      />
      {unfinishedInvoices.length > 0 && (
        <UnfinishedPeriodRow invoices={unfinishedInvoices as any[]} />
      )}
    </Wrapper>
  );
}

export function InvoicePeriodLoader() {
  return (
    <>
      <Wrapper>
        <ContentLoader height={174} width="100%">
          <rect x="0" y="0" width="220" height="22" />
          <rect x="0" y="44" width="700" height="35" />

          <rect x="0" y="100" width="30" height="30" />
          <rect x="50" y="100" width="300" height="30" />
          <rect x="370" y="100" width="30" height="30" />
          <rect x="500" y="100" width="200" height="30" />

          <rect x="0" y="150" width="30" height="30" />
          <rect x="50" y="150" width="320" height="30" />
          <rect x="390" y="150" width="30" height="30" />
          <rect x="500" y="150" width="200" height="30" />
        </ContentLoader>
      </Wrapper>
      <Wrapper>
        <ContentLoader height={274} width="100%">
          <rect x="0" y="0" width="220" height="22" />
          <rect x="0" y="44" width="700" height="35" />

          <rect x="0" y="100" width="30" height="30" />
          <rect x="50" y="100" width="300" height="30" />
          <rect x="370" y="100" width="30" height="30" />
          <rect x="500" y="100" width="200" height="30" />

          <rect x="0" y="150" width="30" height="30" />
          <rect x="50" y="150" width="320" height="30" />
          <rect x="390" y="150" width="30" height="30" />
          <rect x="500" y="150" width="200" height="30" />

          <rect x="0" y="200" width="30" height="30" />
          <rect x="50" y="200" width="300" height="30" />
          <rect x="370" y="200" width="30" height="30" />
          <rect x="500" y="200" width="200" height="30" />

          <rect x="0" y="250" width="30" height="30" />
          <rect x="50" y="250" width="320" height="30" />
          <rect x="390" y="250" width="30" height="30" />
          <rect x="500" y="250" width="200" height="30" />
        </ContentLoader>
      </Wrapper>
    </>
  );
}
