import * as React from 'react';
import 'styled-components/macro';

import { useGraphqlForm } from '../../../components/graphql-form/hooks/useGraphqlForm';
import {
  GraphqlFormField,
  GraphqlFormInputGroup,
} from '../../../components/graphql-form/render';
import { Grid } from '../../../components/layout';

import type { FormPartProps } from './sharedFormParts';

export function AddressForm({ fieldNamePrefix }: FormPartProps) {
  const { isEditing } = useGraphqlForm();
  const prefix = fieldNamePrefix ? `${fieldNamePrefix}.` : '';

  if (!isEditing) {
    return (
      <>
        <GraphqlFormInputGroup>
          <GraphqlFormField name={`${prefix}streetName`} label={null} />
          <GraphqlFormField name={`${prefix}streetNumber`} label={null} />
        </GraphqlFormInputGroup>
        <GraphqlFormInputGroup>
          <GraphqlFormField name={`${prefix}zipCode`} label={null} />
          <GraphqlFormField name={`${prefix}city`} label={null} />
          <GraphqlFormField name={`${prefix}country`} label={null} />
        </GraphqlFormInputGroup>
      </>
    );
  }

  return (
    <Grid $flow="row">
      <Grid
        css={`
          grid-template-columns: 4fr 1fr;
        `}
      >
        <GraphqlFormField name={`${prefix}streetName`} label={null} />
        <GraphqlFormField
          name={`${prefix}streetNumber`}
          label={null}
          placeholder="Nr."
        />
      </Grid>
      <Grid
        css={`
          grid-template-columns: 2fr 3fr 1fr;
        `}
      >
        <GraphqlFormField name={`${prefix}zipCode`} label={null} />
        <GraphqlFormField name={`${prefix}city`} label={null} />
        <GraphqlFormField name={`${prefix}country`} label={null} />
      </Grid>
    </Grid>
  );
}
