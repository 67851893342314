import { DeepExtractType } from 'ts-deep-extract-types';
import { DocumentType } from '@ampeersenergy/ampeers-ui-components';

import { ReadDocumentsQuery } from '../../../../graphql-types';
import { SessionState } from '../../../../redux/session';
import { _ae_env_ } from '../../../../helpers/env';

export function getDocumentTypes(
  documents: DeepExtractType<ReadDocumentsQuery, ['readDocuments']>,
  session: SessionState,
): DocumentType[] {
  return documents.reduce<DocumentType[]>((acc, cur) => {
    const type = mapDocTypeToTemplateName(cur.type);
    const existingType = acc.find((el) => el.type === type);

    const fileURL = new URL(cur.fileURL, _ae_env_.REACT_APP_BACKEND_BASE_URI);
    fileURL.searchParams.set('token', session.token!);
    fileURL.searchParams.set('client_id', session.clientId!);
    fileURL.searchParams.set('attachment', 'true');

    const document = {
      createdAt: cur.createdAt,
      fileURL: fileURL.toString(),
      contentType: cur.contentType,
      fileName: cur.fileName ?? undefined,
      id: cur.hash ?? undefined,
      type: cur.type,
    };

    if (existingType) {
      const existingTypeWithDocument = {
        ...existingType,
        documents: [...(existingType.documents ?? []), document],
      };
      const accWithoutExisting = acc.filter((el) => el.type !== type);
      return [...accWithoutExisting, existingTypeWithDocument];
    }
    const newType = {
      type,
      documents: [document],
      creation: {
        disabled: true,
        hint: 'disabled',
      },
      deletion: {
        enabled: false,
      },
      previewEnabled: false,
    };
    return [...acc, newType];
  }, []);
}

export function mapDocTypeToTemplateName(docType: string) {
  switch (docType) {
    case 'contract_energy_delivery':
      return 'Stromliefervertrag';
    case 'tariff_price_regulation':
      return 'Tarif- und Preisregelung';
    case 'dismissal_notice':
      return 'Kündigungsschreiben';
    case 'cover_letter_contract_new_build':
      return 'Anschreiben Vertrag Neubau';
    case 'cover_letter_contract':
      return 'Anschreiben Vertrag';
    case 'business_terms_energy_delivery':
      return 'AGB Stromlieferung';
    case 'dismissal_warrant':
      return 'Vollmacht Kündigung';
    default:
      return 'Unbekanntes Dokument';
  }
}
