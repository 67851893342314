/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useMemo, useCallback } from 'react';
import { Button, ColumnDefinition } from '@ampeersenergy/ampeers-ui-components';

import { Table } from '../../components/new/Table';
import { PriceSheet } from '../../graphql-types';

import EditPricesheet from './editPricesheet';
import CreatePricesheetFlow from './create/pricesheet/CreatePricesheetFlow';

interface EditPricesheetTableProps {
  tariff?: any;
  isLoading: boolean;
  refetch?: () => void;
}

export default function PricesheetTable({
  tariff,
  isLoading,
  refetch,
}: EditPricesheetTableProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const columns: ColumnDefinition<any>[] = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Bezeichnung',
        accessor: 'name',
        Cell: ({ row }: { row: any }) => {
          return row.isExpanded ? (
            <EditPricesheet
              variables={{
                tariff,
                priceSheet: row.original,
              }}
              refetch={refetch}
            />
          ) : (
            <span>{row.allCells[0].value}</span>
          );
        },
      },
      {
        Header: 'Gültigkeit',
        accessor: 'startDate',
        type: 'date',
      },
    ],
    [tariff, refetch],
  );

  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({
    0: true,
  });

  const sortedPriceSheets = useMemo(() => {
    if (!tariff?.priceSheets) return [];

    return tariff.priceSheets
      .slice(0)
      .map((sheet: PriceSheet, index: number) => ({
        ...sheet,
        colSpan: expanded[index] ? 2 : 1,
      }));
  }, [expanded, tariff]);

  const renderTableActions = useCallback(
    () => (
      <Button id="create-pricesheet-btn" onClick={() => setModalIsOpen(true)}>
        Neues Preisblatt anlegen
      </Button>
    ),
    [],
  );

  const onExpandedChange = useCallback(
    (_: any, rowId: number) => {
      const newExp: any = {};
      newExp[rowId] = true;
      if (newExp[rowId] === expanded[rowId]) {
        newExp[rowId] = false;
      }
      setExpanded(newExp);
    },
    [expanded],
  );

  return (
    <>
      <Table
        columns={columns}
        filterKind="Preisblatt"
        data={sortedPriceSheets}
        renderTableActions={renderTableActions}
        expanded={expanded}
        onExpandedChange={onExpandedChange}
        hideCellsOnExpand
        pageSize={5}
        compact
        withAlternatingRows
        withBoxShadow
        withExpandableRow
        withTooltip={false}
        isLoading={isLoading}
        initialState={{
          sortBy: [
            {
              id: 'startDate',
              desc: true,
            },
          ],
        }}
      />
      <CreatePricesheetFlow
        tariff={tariff}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
      />
    </>
  );
}
