import {
  AlertRetryable,
  FlexRow,
  Input,
} from '@ampeersenergy/ampeers-ui-components';
import { DateTime, Duration } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';
import styled from 'styled-components';

import { useHasRole } from '../../../../../components/useHasRole';
import {
  AccountMove,
  InvoiceType,
  ReadContractAccountingDocument,
  ReadContractDocument,
  useCancelInvoiceMutation,
  useReadContractAccountingQuery,
} from '../../../../../graphql-types';
import VoucherBookingList from '../voucherBookings/VoucherBookingList';
import VoucherBookingModal from '../voucherBookings/VoucherBookingModal';
import {
  LinkedTab,
  LinkedTabs,
} from '../../../../../components/new/LinkedTabs';

import { AccountingMoveDetail } from './accountingMoveDetail';
import { AccountingMoveList } from './accountingMoveList';
import { Unprocessed } from './unprocessed';

const sortByBookingDate = (a: AccountMove, b: AccountMove) =>
  new Date(b.bookedAt).getTime() - new Date(a.bookedAt).getTime();

export function AccountingMoves({
  contractId,
  contractLabel,
  contractStartDate,
  isLoading: parentLoading,
}: {
  contractId?: string;
  contractLabel?: string;
  contractStartDate?: string;
  isLoading: boolean;
}) {
  const [cancelInvoiceError, setCancelInvoiceError] = useState(false);
  const { hasRole: hasOperationRole } = useHasRole('ae-operations');
  const { hasRole: hasVoucherBookingRole } = useHasRole(
    'feature_voucher_booking',
  );
  const [detailAccountMove, setDetailAccountMove] =
    useState<AccountMove | null>(null);
  const basePath = useResolvedPath('').pathname;

  const [timeRange, setTimeRange] = useState({
    startAt: DateTime.now()
      .minus(Duration.fromObject({ years: 1 }))
      .set({ month: 1, day: 1 })
      .startOf('day')
      .toISO(),
    endAt: DateTime.now().endOf('day').toISO(),
  });
  const location = useLocation();

  const readContractAccountingVariables = useMemo(
    () => ({
      contractId: contractId!,
      contractLabel: contractLabel!,
      startAt: DateTime.fromISO(timeRange.startAt).startOf('day').toISO(),
      endAt: DateTime.fromISO(timeRange.endAt).endOf('day').toISO(),
    }),
    [contractId, contractLabel, timeRange.endAt, timeRange.startAt],
  );

  const { data, error, loading } = useReadContractAccountingQuery({
    variables: readContractAccountingVariables,
    skip: parentLoading,
  });

  const [cancelInvoiceMutation, cancelInvoiceResult] =
    useCancelInvoiceMutation();

  const downPayments = useMemo(() => {
    if (data?.readAccountMoves) {
      return data.readAccountMoves.processed
        .filter((m) => m.type === InvoiceType.DownPayment)
        .sort(sortByBookingDate);
    }
    return [];
  }, [data]);

  const finalSettlements = useMemo(() => {
    if (data?.readAccountMoves) {
      return data.readAccountMoves.processed
        .filter((m) => m.type === InvoiceType.FinalSettlement)
        .sort(sortByBookingDate);
    }
    return [];
  }, [data]);

  const vouchers = useMemo(() => {
    if (data?.readAccountMoves?.vouchers) {
      return Array.from(data.readAccountMoves.vouchers).sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      );
    }
    return [];
  }, [data]);

  const onDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setTimeRange((prevTimeRange) => ({
        ...prevTimeRange,
        [name]: DateTime.fromSQL(value).toISO(),
      }));
    },
    [setTimeRange],
  );

  const renderDatePicker = useCallback(
    () => (
      <Inputs>
        <Input
          id="startAt"
          type="date"
          name="startAt"
          value={DateTime.fromISO(timeRange.startAt).toSQLDate()!}
          onChange={onDateChange}
        />
        <Input
          id="endAt"
          type="date"
          name="endAt"
          value={DateTime.fromISO(timeRange.endAt).toSQLDate()!}
          onChange={onDateChange}
        />
      </Inputs>
    ),
    [onDateChange, timeRange.endAt, timeRange.startAt],
  );

  const onCancelInvoice = useCallback(
    async (payload: any) => {
      try {
        await cancelInvoiceMutation({
          variables: payload,
          refetchQueries: [
            {
              query: ReadContractAccountingDocument,
              variables: readContractAccountingVariables,
            },
            {
              query: ReadContractDocument,
              variables: { contractId },
            },
          ],
        });
        setDetailAccountMove(null);
      } catch (e) {
        setCancelInvoiceError(true);
      }
    },
    [cancelInvoiceMutation, contractId, readContractAccountingVariables],
  );

  return (
    <LinkedTabs basePath={basePath}>
      <LinkedTab path="down-payments" title="Abschläge">
        <>
          {error && <AlertRetryable error={error} />}
          {renderDatePicker()}
          <AccountingMoveList loading={loading} entries={downPayments} />
        </>
      </LinkedTab>
      <LinkedTab path="final-settlements" title="Abrechnungen">
        <>
          {error && <AlertRetryable error={error} />}
          {renderDatePicker()}
          <AccountingMoveList
            entries={finalSettlements}
            loading={loading}
            onClick={(curItem) => setDetailAccountMove(curItem)}
          />

          {detailAccountMove && (
            <AccountingMoveDetail
              accountingMove={detailAccountMove}
              contractId={contractId!}
              contractLabel={contractLabel!}
              onClose={() => {
                setDetailAccountMove(null);
                setCancelInvoiceError(false);
              }}
              onCancelInvoice={onCancelInvoice}
              error={cancelInvoiceError}
              isLoading={cancelInvoiceResult.loading}
            />
          )}
        </>
      </LinkedTab>
      {hasVoucherBookingRole ? (
        <LinkedTab path="bonus" title="Boni">
          {error && <AlertRetryable error={error} />}
          <TopBar>
            <VoucherBookingModal
              contractId={contractId}
              contractLabel={contractLabel}
              contractStart={contractStartDate}
              startAt={timeRange.startAt}
              endAt={timeRange.endAt}
            />
            {renderDatePicker()}
          </TopBar>
          <VoucherBookingList entries={vouchers} />
        </LinkedTab>
      ) : null}
      {hasOperationRole ? (
        <LinkedTab
          path="unprocessed"
          title="Nicht verarbeitet"
          hasNotification={data?.readAccountMoves?.unprocessed.length !== 0}
        >
          <Unprocessed
            entries={data?.readAccountMoves?.unprocessed}
            isLoading={loading}
          />
        </LinkedTab>
      ) : null}
    </LinkedTabs>
  );
}

const Inputs = styled(FlexRow)`
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;

  > div:first-child {
    margin-right: 5px;
  }
`;

const TopBar = styled(FlexRow)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;
