/* eslint-disable no-nested-ternary */
import React from 'react';
import { Route, useParams, Routes, useResolvedPath } from 'react-router-dom';

import { Icons, SubTitle } from '../../components';
import { DocTitle } from '../../components/docTitle';
import ErrorMsg from '../../components/errorMsg';
import { NestedPageParent } from '../../components/nestedPage';
import { SuccessMsg } from '../../components/successMessage';
import {
  useListPlantsQuery,
  usePlantDetailExtendedQuery,
} from '../../graphql-types';
import ContractIndexPage from '../contract/pages/index';
import { LinkedTab, LinkedTabs } from '../../components/new/LinkedTabs';
import { PageTitleLayout } from '../../components/new/PageTitleLayout';

import GenerationPlants from './generationPlants';
import { MeterDetailPage } from './meter';
import Meters from './meters';
import MasterData from './pages/masterData/masterData';
import Reports from './reports';
import Tenants from './tenants';

const projectDetailTitle = 'Kundenanlage';

function ProjectDetail() {
  const { plantId, projectId } = useParams<{
    plantId: string;
    projectId: string;
  }>();
  const { data, error, loading } = usePlantDetailExtendedQuery({
    variables: {
      plantId: plantId!,
    },
  });
  const basePath = useResolvedPath('').pathname;

  const { data: projectResult } = useListPlantsQuery({
    variables: {
      projectId: projectId!,
    },
    skip: data === undefined,
  });

  const isSinglePlantInProject =
    projectResult?.readProject?.plants.length === 1;

  let inner;

  if (plantId === null) {
    inner = <ErrorMsg message="Id der Kundenanlage fehlt." />;
  } else if (!plantId) {
    inner = <ErrorMsg message="Id der Kundenanlage ist invalide." />;
  } else if (error) {
    inner = <ErrorMsg error={error} />;
  } else {
    inner = (
      <LinkedTabs basePath={basePath}>
        <LinkedTab title="Mieter" path="tenant" isGeneric>
          <>
            {data?.readPlant?.isInSetup && (
              <SuccessMsg>
                <SubTitle>
                  <Icons.Checkmark size={25} color="#A8E015" />
                  Erfolgreich angelegt
                </SubTitle>
                Um die Einrichtung abzuschließen, als nächstes Verträge für die
                Gebäudezähler anlegen.
              </SuccessMsg>
            )}
            <Tenants data={data} isLoading={loading} />
          </>
        </LinkedTab>
        <LinkedTab
          title="Gebäude"
          hasNotification={data?.readPlant?.isInSetup ?? false}
          path="building"
          isGeneric
        >
          <GenerationPlants data={data} isLoading={loading} />
        </LinkedTab>
        <LinkedTab title="Zähler" path="meter" isGeneric>
          <Meters plantId={plantId} />
        </LinkedTab>
        <LinkedTab title="Stammdaten" path="masterdata">
          <MasterData data={data} isLoading={loading} />
        </LinkedTab>
        <LinkedTab title="Berichte" path="reports" isGeneric>
          <Reports data={data} isLoading={loading} />
        </LinkedTab>
      </LinkedTabs>
    );
  }

  const titleParts = loading
    ? [projectDetailTitle]
    : data?.readPlant?.name
    ? [data?.readPlant?.name, projectDetailTitle]
    : [projectDetailTitle];

  return (
    <>
      <DocTitle titleParts={titleParts} />
      <NestedPageParent>
        <PageTitleLayout
          levelsUpToParent={isSinglePlantInProject ? 4 : 3}
          isLoading={loading}
          title={data?.readPlant?.name || ''}
        >
          {inner}
          <Routes>
            <Route path="meter/:meterId" element={<MeterDetailPage />} />
            <Route path="meter/:meterId/*" element={<MeterDetailPage />} />
            <Route
              path="tenant/contract/:contractId-:contractLabelDeprecated"
              element={<ContractIndexPage />}
            />
            <Route
              path="building/contract/:contractId-:contractLabelDeprecated"
              element={<ContractIndexPage />}
            />
            <Route
              path="tenant/contract/:contractId/*"
              element={<ContractIndexPage />}
            />
            <Route
              path={`${basePath}/tenant/contract/:contractId`}
              element={<ContractIndexPage />}
            />
            <Route
              path="building/contract/:contractId"
              element={<ContractIndexPage />}
            />
            <Route
              path="building/contract/:contractId/*"
              element={<ContractIndexPage />}
            />
          </Routes>
        </PageTitleLayout>
      </NestedPageParent>
    </>
  );
}

export default ProjectDetail;
