import { DateTime } from 'luxon';
import { err, ok } from 'neverthrow';
import * as React from 'react';
import styled from 'styled-components';
import { number, object, string } from 'yup';

import { CreateMultipleDocumentsModal } from '../../../../components/contract-documents/createMultipleDocumentsModal';
import {
  MultipleCreationHandler,
  PreviewHandler,
} from '../../../../components/contract-documents/types';
import { ContractNameLabel } from '../../../../components/contractNameLabel';
import {
  Contract,
  PricesheetChangeDocumentData,
  Tariff,
  useGenerateContractDocumentPreviewMutation,
  useGenerateMultipleContractDocumentsMutation,
  useReadCurrentUserQuery,
} from '../../../../graphql-types';
import { formatContract } from '../../../../helpers/formatStrings';

import PricesheetChangePreview, {
  PricesheetChangeContext,
  usePricesheetReason,
} from './PricesheetChangePreview';
import { PriceSheetInput } from './createPricesheetForm';

const Description = styled.div`
  padding: 2rem 0 1rem;
`;

function generatePricesheetChangeDocumentPayload(
  contract: Contract,
  oldPricesheet: PriceSheetInput,
  newPricesheet: PriceSheetInput,
  tariff: Tariff,
  additional?: { reason?: string },
): PricesheetChangeDocumentData {
  const pricesheetDataSchema = object().shape({
    tariff: object().required('Tariff is required'),
    name: string().required('Name is required'),
    co: string().optional().nullable(),
    meterNumber: string().required('Meter number is required'),
    oldPricesheetEnergyPrice: number().required(
      'Old pricesheet energy price is required',
    ),
    oldPricesheetBasicPrice: number().required(
      'Old pricesheet basic price is required',
    ),
    newPricesheetEnergyPrice: number().required(
      'New pricesheet energy price is required',
    ),
    newPricesheetBasicPrice: number().required(
      'New pricesheet basic price is required',
    ),
    newPricesheetName: string().required('New pricesheet name is required'),
    newPricesheetStartDate: string().required(
      'New pricesheet start date is required',
    ),
    billingAddressStreetName: string().required(
      'Billing address street name is required',
    ),
    billingAddressStreetNumber: string().required(
      'Billing address street number is required',
    ),
    billingAddressZipCode: string().required(
      'Billing address zip code is required',
    ),
    billingAddressCity: string().required('Billing address city is required'),
  });

  const validationResult = pricesheetDataSchema.validateSync({
    tariff,
    name: contract.customer.addressBilling.name,
    co: contract.customer.addressBilling.co,
    meterNumber: contract.contractMeter?.meters[0].meterNumber,
    oldPricesheetEnergyPrice: oldPricesheet.energyPrice,
    oldPricesheetBasicPrice: oldPricesheet.basicPrice,
    newPricesheetEnergyPrice: newPricesheet.energyPrice,
    newPricesheetBasicPrice: newPricesheet.basicPrice,
    newPricesheetName: newPricesheet.name,
    newPricesheetStartDate: newPricesheet.startDate,
    billingAddressStreetName: contract.customer.addressBilling.streetName,
    billingAddressStreetNumber: contract.customer.addressBilling.streetNumber,
    billingAddressZipCode: contract.customer.addressBilling.zipCode,
    billingAddressCity: contract.customer.addressBilling.city,
  });

  const { priceGuarantee, priceGuaranteeReference } = validationResult.tariff;
  const contractStartDate =
    priceGuaranteeReference === 'l' ? contract.startDate : contract.signDate;

  const priceGuaranteeEndDate = DateTime.fromISO(contractStartDate, {
    zone: 'utc',
  })
    .plus({ months: +priceGuarantee })
    .minus({ days: 1 })
    .endOf('day')
    .toISO();

  const { addressBilling } = contract.customer;

  const name =
    addressBilling.companyFlag && addressBilling.co
      ? `${addressBilling.name} c/o ${addressBilling.co}`
      : addressBilling.name!; // TODO: LS-3505 make mailhub co aware

  return {
    meterNumber: validationResult.meterNumber,
    customerLabel: contract.customer.label,
    contractLabel: contract.label,
    tariffName: validationResult.tariff.nameExternal,
    pricesheetName: validationResult.newPricesheetName,
    pricesheetStartDate: DateTime.fromFormat(
      validationResult.newPricesheetStartDate,
      'yyyy-MM-dd',
      { zone: 'utc' },
    ).toISO(),
    salutation: 'Sehr geehrte Damen und Herren',
    priceGuaranteeEndDate,
    name,
    date: DateTime.now().toJSDate(),
    tariffNewPricesheetDetails: {
      basicPrice: Number.parseFloat(
        String(validationResult.newPricesheetBasicPrice),
      ),
      energyPrice: Number.parseFloat(
        String(validationResult.newPricesheetEnergyPrice),
      ),
    },
    tariffOldPricesheetDetails: {
      basicPrice: Number.parseFloat(
        String(validationResult.oldPricesheetBasicPrice),
      ),
      energyPrice: Number.parseFloat(
        String(validationResult.oldPricesheetEnergyPrice),
      ),
    },
    billingAddress: {
      name: validationResult.name,
      additionalName: addressBilling.additionalName,
      streetName: validationResult.billingAddressStreetName,
      streetNumber: validationResult.billingAddressStreetNumber,
      zipCode: validationResult.billingAddressZipCode,
      city: validationResult.billingAddressCity,
      co: validationResult.co,
    },
    workspace: contract.workspace.code,
    reason: additional?.reason ?? '',
    ignorePriceGuarantee: newPricesheet.ignorePriceGuarantee,
  };
}

interface PricesheetChangeDocumentsProps {
  contracts: Contract[];
  newPriceSheet: PriceSheetInput;
  oldPricesheet: PriceSheetInput;
  tariff: Tariff;
  onClose: () => void;
}

function PricesheetChangeDocuments({
  contracts,
  oldPricesheet,
  newPriceSheet,
  tariff,
  onClose,
}: PricesheetChangeDocumentsProps) {
  const [generateMultipleContractDocuments] =
    useGenerateMultipleContractDocumentsMutation();

  const { data: currentUserData } = useReadCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });

  const [generatePreviewMutate] = useGenerateContractDocumentPreviewMutation();
  const { reason } = usePricesheetReason();

  const previewMutate: PreviewHandler<Contract> = React.useCallback(
    async (contract, additional) => {
      const { data, errors } = await generatePreviewMutate({
        variables: {
          type: 'pricesheet_change_letter',
          contractId: contract.id,
          pricesheetChangeDocumentData: generatePricesheetChangeDocumentPayload(
            contract,
            oldPricesheet,
            newPriceSheet,
            tariff,
            additional,
          ),
        },
      });

      if (errors) {
        return err({ reason: errors[0].message });
      }
      if (
        data?.generateContractDocumentPreview.__typename ===
        'ContractDocumentPreview'
      ) {
        return ok({ fileURL: data?.generateContractDocumentPreview.fileURL });
      }

      if (data?.generateContractDocumentPreview.__typename === 'UnknownError') {
        return err({
          reason: data.generateContractDocumentPreview.message,
        });
      }

      if (
        data?.generateContractDocumentPreview.__typename ===
        'MissingAttributesInContractDocumentError'
      ) {
        return err({
          reason: data.generateContractDocumentPreview.attributes.join(', '),
        });
      }

      return err({
        reason: 'Unbekannter Fehler',
      });
    },
    [generatePreviewMutate, oldPricesheet, newPriceSheet, tariff],
  );

  const generateDocumentMutate: MultipleCreationHandler<Contract> =
    React.useCallback(
      async (documentContracts, deliveryPreference) => {
        const payloads = documentContracts.map((contract) => ({
          type: 'pricesheet_change_letter',
          contractId: contract.id,
          deliveryPreference,
          pricesheetChangeDocumentData: generatePricesheetChangeDocumentPayload(
            contract,
            oldPricesheet,
            newPriceSheet,
            tariff,
            { reason },
          ),
        }));

        const { data, errors } = await generateMultipleContractDocuments({
          variables: { payloads, deliveryPreference },
        });

        if (
          data?.generateMultipleContractDocuments.__typename ===
            'GenerateMultipleDocumentsResult' &&
          data.generateMultipleContractDocuments.jobId
        ) {
          return ok(data.generateMultipleContractDocuments.jobId);
        }

        if (errors) {
          return err({ reason: errors[0].message });
        }

        return err({ reason: 'Unbekannter Fehler' });
      },
      [
        generateMultipleContractDocuments,
        oldPricesheet,
        newPriceSheet,
        reason,
        tariff,
      ],
    );

  const renderItem = React.useCallback((c: Contract) => {
    return (
      <ContractNameLabel
        contractName={formatContract(c)}
        contractLabel={c.label}
      />
    );
  }, []);

  const PricesheetChangePreviewWithReason = PricesheetChangePreview;

  return (
    <CreateMultipleDocumentsModal<Contract>
      contracts={contracts ?? []}
      renderItem={renderItem}
      overviewDescription={
        <Description>
          Um die folgenden Kunden von den Änderungen in Kenntnis zu setzen, kann
          in den weiteren Schritten ein Schreiben zur Preisänderung erstellt
          werden.
        </Description>
      }
      type="pricesheet_change_letter"
      onClose={onClose}
      onCreate={generateDocumentMutate}
      onPreview={previewMutate}
      userEmail={currentUserData?.readCurrentUser.email ?? ''}
      getId={(contract) => contract.id}
      PreviewContent={PricesheetChangePreviewWithReason}
    />
  );
}

export default function CreatePricesheetChangeDocuments(
  props: PricesheetChangeDocumentsProps,
) {
  const [reason, setReason] = React.useState('');

  const value = React.useMemo(
    () => ({
      reason,
      updateReason: setReason,
    }),
    [reason],
  );

  return (
    <PricesheetChangeContext.Provider value={value}>
      <PricesheetChangeDocuments {...props} />
    </PricesheetChangeContext.Provider>
  );
}
