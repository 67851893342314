/* eslint-disable react/destructuring-assignment */
import * as React from 'react';
import styled from 'styled-components';
import {
  useDropzone,
  DropzoneRootProps,
  DropzoneInputProps,
  ErrorCode,
  Accept,
  FileRejection,
} from 'react-dropzone';
import { Modal, WarningAlert } from '@ampeersenergy/ampeers-ui-components';

import { ReadContractQuery } from '../../graphql-types';

import WrapperOverlay from './WrapperOverlay';
import { humanReadableSize, listAcceptedTypes } from './fileUploadHelper';

type UploadDropzoneProps = {
  accept?: Accept;
  contract?: ReadContractQuery['readContract'];
  maxSize?: number;
};

const FileUploadContext = React.createContext<{
  accept?: Accept;
  file: File | null;
  maxSize?: number;
  onFileSelect: (file: File | null) => void;
  openFileDialog: () => void;
  isFileDialogActive?: boolean;
}>({
  accept: undefined,
  file: null,
  maxSize: undefined,
  onFileSelect: () => {},
  openFileDialog: () => {},
  isFileDialogActive: false,
});

export const useFileUploadWrapper = () => React.useContext(FileUploadContext);

type DropzoneErrorProps = {
  error: ErrorCode | string | null;
  onClose: () => void;
};

const ErrorContainer = styled.div`
  max-width: 450px;
  font-size: 15px;

  ul {
    margin: 0;
    padding: 0.5rem 2rem;
  }
`;

function DropzoneError({ error, onClose }: DropzoneErrorProps) {
  const { accept, maxSize } = useFileUploadWrapper();

  let errorMessage: JSX.Element | null = null;

  const acceptedTypesList = accept ? listAcceptedTypes(accept) : null;

  switch (error) {
    case ErrorCode.FileInvalidType:
      errorMessage = (
        <>
          Es tut uns leid, der von dir ausgewählte Dateityp wird leider nicht
          unterstützt.
          {acceptedTypesList && <> Erlaubte Formate: {acceptedTypesList}</>}
        </>
      );
      break;
    case ErrorCode.FileTooLarge:
      errorMessage = (
        <>
          Es tut uns leid, die von dir ausgewählte Datei ist leider zu groß.
          {maxSize ? (
            <>
              {' '}
              Bitte wähle eine Datei die nicht größer als{' '}
              <strong>{humanReadableSize(maxSize)}</strong> ist.
            </>
          ) : null}
        </>
      );
      break;
    case ErrorCode.TooManyFiles:
      errorMessage = <>Es kann nur eine Datei hochgeladen werden.</>;
      break;
    default:
      errorMessage = <>Beim auswählen der Datei ist ein Fehler aufgetreten.</>;
      break;
  }

  return (
    <Modal
      isOpen={!!error}
      title="Fehler"
      onRequestClose={onClose}
      contentLabel="dropzone-error-modal"
    >
      <WarningAlert>
        <ErrorContainer>{errorMessage}</ErrorContainer>
      </WarningAlert>
    </Modal>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

interface UploadZoneProps extends UploadDropzoneProps {
  isDragActive?: boolean;
  fileRejections: readonly FileRejection[];
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
}

function UploadZone({
  children,
  contract,
  isDragActive,
  fileRejections,
  getRootProps,
  getInputProps,
}: React.PropsWithChildren<UploadZoneProps>) {
  const [rejectionError, setRejectionError] = React.useState<
    ErrorCode | string | null
  >(null);

  React.useEffect(() => {
    if (fileRejections.length > 0 && fileRejections[0].errors.length > 0) {
      setRejectionError(fileRejections[0].errors[0].code);
    }
  }, [fileRejections]);

  const name = contract?.customer?.person?.name;

  return (
    <Wrapper {...getRootProps()}>
      {isDragActive && <WrapperOverlay name={name} />}
      {children}
      <input {...getInputProps()} />
      <DropzoneError
        error={rejectionError}
        onClose={() => setRejectionError(null)}
      />
    </Wrapper>
  );
}

export default function UploadDropzone(
  props: React.PropsWithChildren<UploadDropzoneProps>,
) {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    open,
    isFileDialogActive,
  } = useDropzone({
    accept: props.accept,
    maxFiles: 1,
    maxSize: props.maxSize,
    multiple: false,
    onDrop: (files) => {
      setSelectedFile(files[0]);
    },
    noClick: true,
    noKeyboard: true,
    preventDropOnDocument: true,
    disabled: !!selectedFile,
  });

  const contextValues = React.useMemo(
    () => ({
      accept: props.accept,
      maxSize: props.maxSize,
      file: selectedFile,
      onFileSelect: setSelectedFile,
      openFileDialog: open,
      isFileDialogActive,
    }),
    [props.accept, props.maxSize, isFileDialogActive, open, selectedFile],
  );

  return (
    <FileUploadContext.Provider value={contextValues}>
      <UploadZone
        {...props}
        isDragActive={isDragActive}
        fileRejections={fileRejections}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
      />
    </FileUploadContext.Provider>
  );
}
