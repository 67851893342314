import * as React from 'react';
import {
  VerticalTabsContainer,
  VerticalTabs,
  TabNavigation,
  Main,
  Header,
  PageGrid,
  Icons,
  Section,
} from '@ampeersenergy/ampeers-ui-components';
import styled from 'styled-components';
import { Link, Route, Routes, useMatch } from 'react-router-dom';

import { DocTitle } from '../../components/docTitle';
import { useHasRole } from '../../components/useHasRole';

import { UserSettings } from './userSettings/userSettings';
import { ContractSettings } from './ContractSettings';
import { EmailSettings } from './EmailSettings';
import { DocumentsSettings } from './DocumentsSettings';
import TestSettings from './TestSettings';
import CookieSettings from './CookieSettings/cookieSettings';
import OpsClientSettings from './OpsClientSettings';

const AlignedSection = styled(Section)`
  padding: 32px 34px 32px 0;
`;

function Settings() {
  const { hasRole: isOperationsUser } = useHasRole('ae-operations');

  const basePath = '/settings';
  const accountMatch = useMatch(`${basePath}/account`);
  const cookiesMatch = useMatch(`${basePath}/cookies`);
  const contractsMatch = useMatch(`${basePath}/contracts`);
  const documentsMatch = useMatch(`${basePath}/documents`);
  const emailMatch = useMatch(`${basePath}/email`);
  const testRequestsMatch = useMatch(`${basePath}/test-requests`);
  const opsClientSettingsMatch = useMatch(`${basePath}/ops-client-settings`);

  // Define the active tab logic using the match results
  const activeTab =
    accountMatch?.pathname ||
    cookiesMatch?.pathname ||
    contractsMatch?.pathname ||
    documentsMatch?.pathname ||
    (isOperationsUser &&
      (emailMatch?.pathname ||
        testRequestsMatch?.pathname ||
        opsClientSettingsMatch?.pathname)) ||
    `${basePath}/account`; // Default to account if no match

  return (
    <>
      <DocTitle titleParts={['Einstellungen']} />
      <Main>
        {/* @ts-expect-error old Header */}
        <Header Icon={Icons.Settings}>Einstellungen</Header>
        <PageGrid>
          <AlignedSection expand>
            <VerticalTabsContainer>
              <TabNavigation>
                <VerticalTabs title="Persönlich" value={activeTab}>
                  <VerticalTabs.Tab
                    label="Account"
                    to={`${basePath}/account`}
                    value={`${basePath}/account`}
                    component={Link}
                  />
                  <VerticalTabs.Tab
                    label="Cookies"
                    to={`${basePath}/cookies`}
                    value={`${basePath}/cookies`}
                    component={Link}
                  />
                </VerticalTabs>
                <VerticalTabs title="Workspace" value={activeTab}>
                  <VerticalTabs.Tab
                    label="Verträge"
                    to={`${basePath}/contracts`}
                    value={`${basePath}/contracts`}
                    component={Link}
                  />
                  <VerticalTabs.Tab
                    label="Dokumente"
                    to={`${basePath}/documents`}
                    value={`${basePath}/documents`}
                    component={Link}
                  />
                </VerticalTabs>
                {isOperationsUser ? (
                  <VerticalTabs title="Operations" value={activeTab}>
                    <VerticalTabs.Tab
                      label="E-Mail Versand"
                      to={`${basePath}/email`}
                      value={`${basePath}/email`}
                      component={Link}
                    />
                    <VerticalTabs.Tab
                      label="Test Settings"
                      to={`${basePath}/test-requests`}
                      value={`${basePath}/test-requests`}
                      component={Link}
                    />
                    <VerticalTabs.Tab
                      label="Ops Client Settings"
                      to={`${basePath}/ops-client-settings`}
                      value={`${basePath}/ops-client-settings`}
                      component={Link}
                    />
                  </VerticalTabs>
                ) : null}
              </TabNavigation>

              <Routes>
                <Route path="account" element={<UserSettings />} />
                <Route path="cookies" element={<CookieSettings />} />
                <Route path="contracts" element={<ContractSettings />} />
                <Route path="documents" element={<DocumentsSettings />} />
                {isOperationsUser && (
                  <>
                    <Route path="email" element={<EmailSettings />} />
                    <Route path="test-requests" element={<TestSettings />} />
                    <Route
                      path="ops-client-settings"
                      element={<OpsClientSettings />}
                    />
                  </>
                )}
              </Routes>
            </VerticalTabsContainer>
          </AlignedSection>
        </PageGrid>
      </Main>
    </>
  );
}

export default Settings;
