import React from 'react';
import * as yup from 'yup';
import { DateTime } from 'luxon';

import { ErrorMsg } from '../../../../../components';
import GraphqlForm from '../../../../../components/graphql-form';
import {
  ReadContractDocument,
  usePlantDetailQuery,
  useReadMeterReadingsQuery,
  Metering,
} from '../../../../../graphql-types';
import { yupUTCDate } from '../../../../../helpers/yupUTCDate';
import { EditContainerProps } from '../../../../../components/createFlow';
import {
  METER_OBIS_CHANNEL_SLP_BEZUG,
  METER_READING_END_DATE,
  METER_READING_START_DATE,
} from '../../../../../helpers/constants';
import { yupNumberAsString } from '../../../../../helpers/yupNumberAsString';
import numeral from '../../../../../helpers/numeral';
import { findMeterReadingMinMaxLimits } from '../../../../../helpers/validators/meterReading';
import { areDatesOnSameDay } from '../../../../../helpers/dateHelpers';

import EditForm from './editForm';

function EditMeter({ onSuccess, onAbort, variables }: EditContainerProps) {
  const {
    data: plantData,
    error,
    loading: isPlantLoading,
  } = usePlantDetailQuery({
    variables: {
      plantId: variables.plantId,
    },
  });

  const { data: dataMeterReading, loading: loadingReadMeterReading } =
    useReadMeterReadingsQuery({
      variables: {
        meterId: variables?.oldMeter?.id,
        startDate: METER_READING_START_DATE,
        endDate: METER_READING_END_DATE,
        metering:
          variables?.oldMeter?.metering === Metering.Rlm
            ? Metering.Rlm
            : Metering.Slp,
      },
      skip: variables?.oldMeter?.id === undefined,
    });
  if (error) {
    return <ErrorMsg error={error} />;
  }

  return (
    <GraphqlForm
      mutation="updateContractMeterChange"
      readDocument={ReadContractDocument}
      variables={{
        contractId: variables.contractId,
        melo: variables.melo,
        oldMeterId: variables.oldMeter.id,
      }}
      onSuccess={onSuccess}
      onAbort={onAbort}
      isLoading={isPlantLoading}
      disableEdit={false}
      startInEdit
      formVariables={{
        balancingAreaAccountId: plantData?.readPlant?.balancingAreaAccountId,
        plantId: variables.plantId,
      }}
      formatBeforeSubmit={(values) => ({
        ...values,
        oldMeterReading: {
          ...values.oldMeterReading,
          value: numeral(values.oldMeterReading.value).value(),
        },
        meterReading: {
          ...values.meterReading,
          value: numeral(values.meterReading.value).value(),
        },
      })}
      validation={{
        validityEndDate: yupUTCDate
          .required()
          .test(
            'oldValidityStartDate',
            'Ausbaudatum darf nicht vor dem Startdatum des alten Zählers liegen.',
            (value: Date | undefined) => {
              if (variables.oldMeter?.validityStart === undefined || !value) {
                return true;
              }
              return (
                new Date(variables.oldMeter?.validityStart).getTime() <
                new Date(value).getTime()
              );
            },
          )
          .test(
            'oldValidityEndDate',
            'Ausbaudatum muss kleiner sein als das Enddatum des Vertrages.',
            (value: Date | undefined) => {
              if (variables.oldMeter?.validityEnd === undefined || !value) {
                return true;
              }
              return (
                DateTime.fromISO(variables.oldMeter?.validityEnd)
                  .setZone('utc')
                  .startOf('day')
                  .toMillis() > value.getTime()
              );
            },
          )
          .test(
            'meterReadingAlreadyExists',
            'Für das angegebene Ausbaudatum existiert bereits ein Zählerstand.',
            (value: Date | undefined) => {
              if (!value) {
                return true;
              }

              return !dataMeterReading?.readMeterReadings
                .map(({ from }) => DateTime.fromISO(from).toJSDate())
                .some((meteringDate) => areDatesOnSameDay(meteringDate, value));
            },
          ),
        oldMeterReading: {
          value: yupNumberAsString
            .required()
            .test(
              'oldMeterReadingValue',
              'An unexpected error occurred',
              function test(this: yup.TestContext, value: string | undefined) {
                const valueAsNumber = numeral(value).value()!;
                const validityEndDate = (this as any).from[1].value
                  ?.validityEndDate;

                if (
                  !validityEndDate ||
                  loadingReadMeterReading ||
                  value === undefined
                ) {
                  return true;
                }

                const formattedDateValue =
                  dataMeterReading?.readMeterReadings
                    ?.filter((c) => c.obis === METER_OBIS_CHANNEL_SLP_BEZUG)
                    ?.map((c) => ({
                      date: c.from,
                      value: String(c.value),
                    })) ?? [];

                const { min, max } = findMeterReadingMinMaxLimits(
                  formattedDateValue,
                  new Date(validityEndDate),
                );

                const firstDate = DateTime.fromISO(
                  formattedDateValue[0]?.date,
                ).toISODate();
                if (typeof max === 'number' && max <= 0) {
                  // eslint-disable-next-line react/no-is-mounted
                  return this.createError({
                    message: `Muss größer 0 sein und nach ${firstDate} liegen`,
                    path: 'oldMeterReading.value',
                  });
                }
                if (min === max) {
                  return valueAsNumber === min
                    ? true
                    : // eslint-disable-next-line react/no-is-mounted
                      this.createError({
                        message: `Muss gleich ${min} sein`,
                        path: 'oldMeterReading.value',
                      });
                }
                if (max) {
                  return valueAsNumber < min || valueAsNumber > max
                    ? // eslint-disable-next-line react/no-is-mounted
                      this.createError({
                        message: `Muss zwischen ${min} und ${max} liegen`,
                        path: 'oldMeterReading.value',
                      })
                    : true;
                }
                return valueAsNumber < min
                  ? // eslint-disable-next-line react/no-is-mounted
                    this.createError({
                      message: `Muss größer oder gleich ${min} sein`,
                      path: 'oldMeterReading.value',
                    })
                  : true;
              },
            ),
          valueStatus: yup.string().required(),
        },
        meterReading: {
          value: yupNumberAsString.min(0).required(),
          valueStatus: yup.string().required(),
        },
      }}
    >
      <EditForm
        plantId={variables.plantId}
        contractStartDate={variables.contractStartDate}
        contractEndDate={variables.contractEndDate}
      />
    </GraphqlForm>
  );
}

export default EditMeter;
