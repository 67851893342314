/* eslint-disable react/no-unused-prop-types */

import React, { ReactNode, useEffect, useState } from 'react';
import {
  Route,
  Link,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import normalize from 'path-normalize';
import styled from 'styled-components';
import {
  TabChildrenProps,
  TabsProps,
} from '@ampeersenergy/ampeers-ui-components';

interface LinkedTabChildrenProps extends Omit<TabChildrenProps, 'lazy'> {
  path: string;
  replace?: boolean;
  exact?: boolean;
  strict?: boolean;
  disabled?: boolean;
  children: ReactNode;
  isGeneric?: boolean;
}

interface LinkedTabsProps extends TabsProps {
  children: (React.ReactElement<LinkedTabChildrenProps> | null)[];
  basePath: string;
}

export const TabContainer = styled.div`
  display: none;

  &.active {
    display: inherit;
  }
`;

export const TabNavigationItem = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.palette.textMuted};
  padding: 15px 0px 9px 0px;
  cursor: pointer;
  margin-right: 50px;
  font-weight: 400;

  &:hover {
    color: ${(props) => props.theme.palette.text};
  }

  &.active {
    border-bottom: 2px solid ${(props) => props.theme.primaryColor};
    color: ${(props) => props.theme.primaryColor};
    font-weight: 600;
  }
`;

export const YellowDot = styled.div`
  width: 9px;
  height: 9px;
  background: #ff9800;
  border-radius: 50px;
  display: inline-block;
  margin-left: 6px;
`;

export const TabNavigation = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.palette.border};
  padding-left: 25px;
  background: ${(props) => props.theme.palette.background};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  a {
    text-decoration: none;
  }
`;

export const LinkedTab: React.FunctionComponent<LinkedTabChildrenProps> = ({
  children,
}) => {
  return <div>{children}</div>;
};

export const LinkedTabs: React.FunctionComponent<LinkedTabsProps> = ({
  children,
  basePath,
}) => {
  const tabs = React.Children.toArray(children);
  const location = useLocation();
  const navigate = useNavigate();

  const firstTabPath =
    tabs.length > 0
      ? normalize(
          `${basePath}/${
            (children[0] as React.ReactElement<LinkedTabChildrenProps>).props
              .path
          }`,
        )
      : '';

  useEffect(() => {
    if (location.pathname === basePath && firstTabPath) {
      navigate(firstTabPath, { replace: true });
    }
  }, [location.pathname, basePath, firstTabPath, navigate]);

  const routes: Array<React.ReactElement<TabChildrenProps>> = [];
  const navigationItems = React.Children.map(children, (child, index) => {
    const c = child as React.ReactElement<LinkedTabChildrenProps>;
    if (!React.isValidElement(c)) {
      return child;
    }

    const path = normalize(`${basePath}/${c.props.path}`);

    routes.push(
      <Route
        path={c.props.path}
        element={
          <TabContainer className="active">{c.props.children}</TabContainer>
        }
        key={path}
      />,
    );
    if (c.props.isGeneric) {
      routes.push(
        <Route
          path={`${c.props.path}/*`}
          element={
            <TabContainer className="active">{c.props.children}</TabContainer>
          }
          key={path}
        />,
      );
    }
    return (
      <Link to={path} replace={c.props.replace ?? true} key={`link-${index}`}>
        <TabNavigationItem
          className={window.location.pathname.includes(path) ? 'active' : ''}
        >
          {c.props.title}
          {c.props.hasNotification && <YellowDot />}
        </TabNavigationItem>
      </Link>
    );
  });

  return (
    <div>
      <TabNavigation>{navigationItems}</TabNavigation>
      <Routes>{routes}</Routes>
    </div>
  );
};
