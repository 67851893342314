import { DateTime } from 'luxon';
import { err, ok, Result } from 'neverthrow';
import React, { useCallback } from 'react';

import {
  ContractsBatchedSuccess,
  CreateContractsBatchedError,
  CreateContractsValidationError,
  CreateMeterReadingsBatchedInput,
  CreateMeterReadingsBatchedMixedResult,
  CreateMetersBatchedError,
  CreateMetersBatchedSuccess,
  PrepareUpdateContractsBatchedResponse,
  useReadPlantMetersQuery,
} from '../../graphql-types';
import CSVService, {
  contractsCsvConfig,
  contractsUpdateCsvConfig,
  meterReadingsCSVConfig,
  metersCSVConfig,
} from '../../services/csv';
import { DocTitle } from '../docTitle';
import { useHasRole } from '../useHasRole';
import { useTaskManager } from '../task-manager/TaskManagerProvider';

import {
  FailedRow,
  ImportError,
  ImportFlow,
  ImportHandler,
  ImportResult,
  ImportType,
  PrepareImportResult,
  Row,
} from './import-flow';

const csvService: CSVService = new CSVService(metersCSVConfig());

type RefetchType = ReturnType<typeof useReadPlantMetersQuery>['refetch'];

const importErrorTitle = 'Datei konnte nicht importiert werden';

function createErrorMessage(e: unknown) {
  let message: string;
  if (Array.isArray(e)) {
    message = e.map((_error) => _error.message).join(',');
  } else if (e instanceof Error) {
    if (e.message.includes('Unexpected token')) {
      // This error happens when the server crashes with 502 Bad Gateway because a big number of files was imported
      message =
        'Leider ist ein Fehler beim Hochladen Deiner Datei aufgetreten. Dies kann an zu vielen Fehlern in der Datei oder an einem hohen Aufkommen von Uploads durch andere Nutzer und Nutzerinnen liegen. Bitte überprüfe die Datei oder verteile die neu anzulegenden Verträge auf zwei Dateien.';
    } else {
      message = e.message.toString();
    }
  } else {
    message = String(e);
  }
  return message;
}

const mapDuplicateValidationError = ({
  duplicateReadings,
  multipleReadings,
}: any) => {
  return [
    ...(duplicateReadings || []).map(
      (meter: CreateMeterReadingsBatchedInput) => ({
        error: `Es gibt Duplikate in der Datei für den Zähler ${meter.meterNumber}.`,
        values: [meter.meterNumber],
      }),
    ),
    ...(multipleReadings || []).map(
      (meter: CreateMeterReadingsBatchedInput) => ({
        error: `Für den Zähler ${meter.meterNumber} gibt es zu einem Zeitpunkt unterschiedliche Zählerstände.`,
        values: [meter.meterNumber],
      }),
    ),
  ];
};

export function UploadModal({
  setIsOpen,
  refetch,
}: {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetch?: RefetchType;
}) {
  const { hasRole: isOperationsUser } = useHasRole('ae-operations');

  const { hasRole: hasContractUpdateImportEnabled } = useHasRole(
    'feature_contract_update_import',
  );
  const { addJobToTaskManager } = useTaskManager();

  const contractModalType: ImportType[] = [
    {
      label: 'Neue Verträge (AE-Wechselliste)',
      id: 'contracts',
      templateURL: '/contracts_upload_template.csv',
      mimeTypes: ['text/csv', 'application/vnd.ms-excel'],
      acceptedExtensions: ['csv', 'CSV'],
      indeterminateProgress: true,
      importTypeInstructions: 'csv',
    },
  ];

  const contractUpdateModalType: ImportType[] = [
    {
      label: 'Existierende Verträge (AE-Wechselliste)',
      id: 'contracts_update',
      templateURL: '/contracts_update_upload_template.csv',
      mimeTypes: ['text/csv', 'application/vnd.ms-excel'],
      acceptedExtensions: ['csv', 'CSV'],
      indeterminateProgress: true,
      importTypeInstructions: 'csv',
    },
  ];

  const modalTypes: ImportType[] = [
    {
      label: 'Zählerstände (AE-Wechselliste)',
      id: 'meterReading',
      templateURL: '/meter_readings_upload_template.csv',
      mimeTypes: ['text/csv', 'application/vnd.ms-excel'],
      acceptedExtensions: ['csv', 'txt', 'CSV'],
      indeterminateProgress: true,
      importTypeInstructions: 'csv',
    },
    {
      label: 'Zähler (AE-Wechselliste)',
      id: 'meters',
      templateURL: '/meters_upload_template.csv',
      mimeTypes: ['text/csv', 'application/vnd.ms-excel'],
      acceptedExtensions: ['csv', 'CSV'],
      indeterminateProgress: true,
      importTypeInstructions: 'csv',
    },
    ...(hasContractUpdateImportEnabled ? contractUpdateModalType : []),
    ...(isOperationsUser ? contractModalType : []),
  ];

  const onImport: ImportHandler = async ({ type, file, error }) => {
    if (!file || error) {
      const errorMsg: string = error instanceof Error ? error.message : error!;
      return err({
        title: importErrorTitle,
        message: errorMsg.includes('File type must be')
          ? `Ungültiges Format. Datei muss die Endung ${type.acceptedExtensions
              ?.map((s) => `.${s}`)
              .join(', ')} haben.`
          : errorMsg,
      });
    }

    try {
      switch (type.id) {
        case 'meters': {
          csvService.setConfig(metersCSVConfig());
          const uploadResult = await csvService.uploadFiles([file]);
          if (refetch) refetch();

          const failed = uploadResult.failed.map(
            (failedResult: CreateMetersBatchedError) => {
              return {
                error: failedResult.error,
                values: [
                  failedResult.meterNumber,
                  failedResult.plantName,
                  failedResult.message,
                ],
              };
            },
          );
          const succeeded = uploadResult.succeeded.map(
            (succeededResult: CreateMetersBatchedSuccess) => {
              return {
                values: [
                  succeededResult.meterNumber,
                  succeededResult.plantName,
                  'success',
                ],
              };
            },
          );

          return ok({
            failed,
            succeeded,
            headers: ['Zählernummer', 'Anlage', 'Status'],
          });
        }
        case 'meterReading': {
          try {
            csvService.setConfig(meterReadingsCSVConfig);
            const uploadResult = await csvService.uploadFiles([file]);

            if (uploadResult.__typename === 'DuplicateMeterReadingsError') {
              return ok({
                succeeded: [],
                failed: mapDuplicateValidationError(uploadResult),
                headers: ['Zählernummer'],
              });
            }
            return ok({
              succeeded: uploadResult.succeeded.map(
                (
                  succeededResult: CreateMeterReadingsBatchedMixedResult['succeeded'][0],
                ) => {
                  return {
                    values: [succeededResult?.meterNumber],
                  };
                },
              ),
              failed: uploadResult.failed.map(
                (
                  failedResult: CreateMeterReadingsBatchedMixedResult['failed'][0],
                ) => {
                  return {
                    error: failedResult?.error,
                    values: [failedResult?.meterNumber],
                  };
                },
              ),
              headers: ['Zählernummer'],
            });
          } catch (e) {
            return err({
              message: createErrorMessage(e),
              title: importErrorTitle,
            });
          }
        }
        case 'contracts': {
          try {
            csvService.setConfig(contractsCsvConfig());
            const uploadResult:
              | ContractsBatchedSuccess
              | CreateContractsBatchedError = await csvService.uploadFiles([
              file,
            ]);
            if (refetch) refetch();

            if (uploadResult.__typename === 'CreateContractsBatchedError') {
              const failed = uploadResult.failedContracts.map(
                (failedResult: CreateContractsValidationError) => {
                  return {
                    error: failedResult.message,
                    values: [
                      failedResult.customerLabel,
                      failedResult.contractLabel,
                    ],
                  };
                },
              );
              return ok({
                failed,
                warningMessage: uploadResult.message,
                succeeded: [],
                backgroundTask: true,
                headers: ['Kunden-Nr.', 'Vertrags-Nr.'],
              });
            }
            if (uploadResult.__typename === 'ContractsBatchedSuccess') {
              addJobToTaskManager(uploadResult.jobId, uploadResult.jobName);
              return ok({
                backgroundTask: true,
              });
            }

            return err({
              message: 'Unknown error',
              title: importErrorTitle,
            });
          } catch (e) {
            return err({
              message: createErrorMessage(e),
              title: importErrorTitle,
            });
          }
        }
        case 'contracts_update': {
          try {
            csvService.setConfig(contractsUpdateCsvConfig());
            const uploadResult: PrepareUpdateContractsBatchedResponse =
              await csvService.uploadFiles([file]);
            if (refetch) refetch();

            const failed = uploadResult.failed.map((result) => ({
              error: result.message,
              values: [result.contractLabel],
            }));
            return {
              valid: uploadResult.valid,
              failed,
            } as PrepareImportResult;
          } catch (e) {
            return err({
              message: createErrorMessage(e),
              title: importErrorTitle,
            });
          }
          break;
        }
        default:
          break;
      }
    } catch (e) {
      if (e instanceof Error) {
        return err({
          title: importErrorTitle,
          message: e.message,
        });
      }
    }

    return err({
      title: importErrorTitle,
      message: 'Nicht unterstütztes Datei-Format.',
    });
  };

  const onImportOverviewDownload = useCallback(
    (result: Result<ImportResult, ImportError>, type: ImportType) => {
      if (result.isOk()) {
        switch (type.id) {
          case 'meterReading': {
            const fields = ['meterNumber', 'status', 'date'];
            const headers = ['Zaehlernummer', 'Status', 'Datum'];
            const date = DateTime.utc().setLocale('de').toLocal();
            const fileNameDate = date.toFormat('ddMMyyyyHHmm');
            const fileName = `${fileNameDate}_Importlog_Zaehler.csv`;
            const config = { fields, headers, fileName };

            const csvDate = date.toFormat('dd.MM.yyyy');
            const succeeded =
              result.value.succeeded?.map((row) => ({
                meterNumber: row.values[0],
                status: 'SUCCESS',
                date: csvDate,
              })) ?? [];

            const failed =
              result.value.failed?.map((row) => ({
                meterNumber: row.values[0],
                status: row.error,
                date: csvDate,
              })) ?? [];

            return CSVService.downloadCSV([...succeeded, ...failed], config);
          }
          case 'meters': {
            const fields = ['meterNumber', 'plant', 'status', 'date'];
            const headers = ['Zaehlernummer', 'Anlage', 'Status', 'Datum'];
            const date = DateTime.utc().setLocale('de').toLocal();
            const fileNameDate = date.toFormat('ddMMyyyyHHmm');
            const fileName = `${fileNameDate}_Importlog_Zaehler.csv`;
            const config = { fields, headers, fileName };

            const csvDate = date.toFormat('dd.MM.yyyy');
            const succeeded = generateMeterCsvObject(
              result.value.succeeded ?? [],
              csvDate,
            );
            const failed = generateMeterCsvObject(
              result.value.failed ?? [],
              csvDate,
            );

            return CSVService.downloadCSV([...succeeded, ...failed], config);
          }
          case 'contracts': {
            const fields = ['customerLabel', 'contractLabel', 'status', 'date'];
            const headers = ['Kunden-Nr.', 'Vertrags-Nr.', 'Status', 'Datum'];
            const date = DateTime.utc().setLocale('de').toLocal();
            const fileNameDate = date.toFormat('ddMMyyyyHHmm');
            const fileName = `${fileNameDate}_Importlog_Vertraege.csv`;
            const config = { fields, headers, fileName };

            const csvDate = date.toFormat('dd.MM.yyyy');

            const failed =
              result.value.failed?.map((failedRow) => ({
                customerLabel: failedRow.values[0],
                contractLabel: failedRow.values[1],
                status: failedRow.error,
                date: csvDate,
              })) ?? [];

            return CSVService.downloadCSV(failed, config);
          }
          default:
            return null;
        }
      } else {
        console.error(
          'Something went wrong while trying to generate import overview',
          { error: result.error },
        );
      }
    },
    [],
  );

  return (
    <>
      <DocTitle titleParts={['Import']} />
      <ImportFlow
        types={modalTypes}
        onImport={onImport}
        onClose={() => setIsOpen(false)}
        onImportOverviewDownload={onImportOverviewDownload}
        defaultType="meters"
      />
    </>
  );
}

function generateMeterCsvObject(rows: Array<Row | FailedRow>, date: string) {
  return rows.map((result) => ({
    meterNumber: result.values[0],
    plant: result.values[1],
    status: result.values[2],
    date,
  }));
}
